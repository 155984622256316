import lost from '../assets/img/illustrations/404b.svg';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
function Lost() {
	return (
		<>
			<Header />
			<div className='fullpage'>
				<div className='row d-flex justify-content-center align-items-center'>
					<div className='lead mt-7 display-5'>
						<Link to='/contact'>
							<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center align-items-center'>
								<div className='lead alert-success alert-heading'>
									You seem Lost. Use the header to find yourself or Contact Us
								</div>
							</div>
						</Link>
					</div>
					<div className='col-12 col-lg-12 text-center'>
						<img src={lost} className='img-fluid image404' alt='404' />
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Lost;
