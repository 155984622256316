
import blogs from '../Data/blogs';
import { Link } from 'react-router-dom';

function Blog({title}) {
	return (
		<div className='mt-n6'>
			<div className='section bg-primary section-lg'>
				<div className='container'>
					{/* <!-- Title --> */}
					<div className='row'>
						<div className='col text-center'>
							<h1 className='h3 mb-6'>{title}</h1>
						</div>
					</div>
					{/* <!-- End of title--> */}
					<div className='row mb-5'>
					{blogs.map((item, index) => {
        return (
            <div key={index} className='col-12 col-md-6 col-lg-4 mb-5'>
                <div className='card bg-primary border-light shadow-soft h-100'>
                    <div className='card-header p-3'>
                        <img
                            src={item.blogHero}
                            className='card-img-top rounded'
                            alt={item.blogHeroAlt}
                        />
                    </div>
                    <div className='card-body pt-2'>
                        <div className='media d-flex align-items-center justify-content-between'>
                            <div className='post-group'>
                                <Link
                                    to='#'
                                    data-bs-toggle='tooltip'
                                    data-placement='top'
                                    title=''
                                    data-original-title='23k followers'
                                >
                                    <img
                                        className='avatar-sm mr-2 img-fluid rounded-circle'
                                        src={item.writerImg}
                                        alt={item.writerImgAlt}
                                    />{' '}
                                    {item.writerName}
                                </Link>
                            </div>
                            <div className='d-flex align-items-center'>
                                <span className='small'>
                                    <span className='far fa-calendar-alt mr-2'></span>
                                    {item.datePublished}
                                </span>
                            </div>
                        </div>
                        <h3 className='h5 card-title mt-4'>{item.blogTitle}</h3>
                        <p className='card-text'>{item.excerpt}</p>
                        <a
                            href={item.CTA.link}
                            className='btn btn-primary btn-sm text-danger text-danger'
                        >
                            {item.CTA.words}
                        </a>
                    </div>
                </div>
            </div>
        );
    })}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Blog;
