import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
// import ProductDetails from '../components/ProductDetailsAbout';
// import clients from '../Data/clients';
// import FeatureCard from '../components/FeatureCard';
// import products from '../Data/products';
// import SocialProof from '../components/SocialProof';
// import Creatives from '../components/Creatives';
// import Services from '../components/Services';
// import CeoSpeech from '../components/CeoSpeech';
import ZicSMS from '../assets/img/products/17.svg';
import zisSmsFeatures from '../Data/products/zicsms/zicsmsFeatures';
import Faq from '../components/Faq';
import { Link } from 'react-router-dom';
import sms from '../assets/img/illustrations/pupils.png';
import { useEffect } from 'react';

function Zicsms() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header pb-7'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicSMS}
									className='img-fluid image-sm'
									alt='Zic Intranet logo'
								/>
								<div className='lead mb-3 mt-5'>
									A leading School Management System built on cutting-edge
									technology that caters for 80% of the Operations of
									Organizations and Businesses.
								</div>
								<img
									src={sms}
									className='img-fluid illusSizeSms'
									alt='Zic Intranet logo'
								/>
							</h1>
							<div className='lead mb-5'>
								<ul className='mt-5'>
									<li>Access to Information, Anytime & Anywhere</li>
									<li>Student Behavior Tracking & Analytics</li>
									<li>Collaborate Beyond Classrooms</li>
								</ul>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn btn-primary alert-heading'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									ZicSMS is an efficient school management software that
									provides a specialized system for all types of models. It
									fulfils the needs of the authorities, staff, students, and
									even guardians. The software has unique features that deal
									with all aspects of the academic setup, starting from
									admissions to result cards. Some of these features include
									pre-existing tools for generating information, a well-managed
									dashboard and user-friendly interface, advanced documentation,
									and strong support. There are different modules for admins,
									students, teachers, accounts, HR, etc., which have
									user-specific features.
								</div>
								<hr />
								<div className='mb-0'>
									The tool also offers single modules such as examination,
									academics, communication, library, dormitory, and reports,
									which can work as a complete package for institutes. Other
									back-end processes such as inventory management,
									transportation management, and system settings are also
									included in this comprehensive software.
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4'>
									Imagine the “BUSINESS INTELLIGENCE” that can be generated a
									couple of years into running operations diligently on our
									system.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn btn-primary alert-heading'>
										How does ZicSMS work?
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									ZicSMS is primarily used for: <br />
									<ul>
										<li>Admin Module,</li>
										<li>Student,</li>
										<li>Information,</li>
										<li>Teacher Management,</li>
										<li>Fees Collection,</li>
										<li>Accounts,</li>
										<li>Human Resource,</li>
										<li>Examination and Result</li>
										<li>Academic activities</li>
										<li>Library Management</li>
										<li>Transport Management</li>
										<li>Inventory and Dormitory</li>
									</ul>
									It has an intuitive interface and is simple to use. For more
									information, you can also refer to ZicSMS user manuals and
									request an online demo at demo@zicstack.com Managing
									Operations across Admin, Staff, and Students is important in
									every School and ZicSMS’s features are tailored around
									delivering exactly this.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Faq data={zisSmsFeatures} title='ZicSMS Features' />
			<section className='section section-lg bg-soft mt-n6'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn btn-primary alert-heading'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicSMS Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Education
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Business Specific: School/Colleges/Coaching
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default Zicsms;
