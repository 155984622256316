import { Link } from 'react-router-dom';

function FeatureCard({ data, title, tagLine }) {
	return (
		<div className='section section-lg pt-0'>
			<div className='container'>
				<div className='row text-center justify-content-center'>
					<div className='col-md-8 mb-5'>
						<h2 className='h4 font-weight-light'>{title}</h2>
						<h5 className='h5 alert-success font-weight-light'>{tagLine}</h5>
					</div>
				</div>
				<div className='row align-items-center'>
					{data.map((item, index) => {
						return (
							item.show && (
								<div key={index} className='col-md-6 col-lg-4 text-center'>
									<Link to={item.link ? item.link : ''}>
										<div className='card bg-primary shadow-soft border-light mb-4'>
											<div className='card-body py-5'>
												{/* <p>Nafdac</p> */}
												<img
													src={item.imgSrc}
													className='img-fluid image-sm clientsImg'
													alt={item.imgAlt}
												/>
											</div>
										</div>
									</Link>
								</div>
							)
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default FeatureCard;
