import { Link } from 'react-router-dom';
import services from '../Data/services';

function Services() {
  return (
    <section className='section section-lg pt-0'>
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div className='card bg-primary shadow-soft border-light p-4'>
              <h3 className='text-center title-heading'>
                <b>Our Services</b>
              </h3>
              <div className='row d-flex align-items-stretch'>
                {services.map((item, index) => {
                  return (
                    <div key={index} className='col-12 col-lg-4 px-md-0 mb-4 mb-lg-0'>
                      <div className='card-body text-center bg-primary py-5'>
                        <div className='icon icon-shape shadow-inset border-light rounded-circle mb-3'>
                          <span className=''>{item.icon}</span>
                        </div>
                        {/* <!-- Heading --> */}
                        <h2 className='h4 mr-2'>{item.title}</h2>
                        {/* <!-- Text --> */}
                        <p className='mb-0'>{item.description}</p>
                        <Link className='btn btn-primary mt-3' to='/contact'>
                          <span className='mr-2'>{item.CTA.icon}</span>
                          {item.CTA.words}
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
