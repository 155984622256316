import Header from "../components/Header"
import Footer from "../components/Footer"
import Blog from "../components/Blog";
import logo from '../assets/img/brand/logo.svg';
import companyName from '../assets/img/brand/zicstack-logo.png';
import {FaPhoneAlt} from "react-icons/fa";
import Faq from "../components/Faq";
import ProductDetailsHome from "../components/ProductDetailsHome";
import WhyUs from "../components/WhyUs";
import PreFooter from "../components/PreFooter";
import faq from "../Data/faq";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { fetchBlogs } from "../api/calls";


function Home() {   
    useEffect(() => {
        window.scrollTo(0, 0);

        const getFetchedBlogs = async () => {
            const blogList = await fetchBlogs();
            console.log("blogList", blogList);
        }

        getFetchedBlogs();
    }, []);

  return (
    <>
    <Header/>
    <div>
        {/* <!-- Hero --> */}
        <section className="section section bg-soft pb-5 overflow-hidden z-2">
            <div className="container z-2">
                <div className="row justify-content-center text-center pt-6">
                    <div className="col-lg-8 col-xl-8">
                        {/* <h1 className="display-2 mb-3">ZICSTACK</h1> */}
                        <img src={companyName} alt="Company Name" width={350} className="mb-3"/>
                        <p className="alert-heading px-md-6 mb-5">Our Job is to help businesses succeed by providing top-notch, results-driven digital services.
                        </p>
                        <p className="lead px-md-6 mb-5"><>Are you looking to scale your business with a Website, Mobile App or Software?</><br/>
                             <span className=""><b>You are in the right place!</b></span>
                        </p>
                        <div className="d-flex flex-column flex-wrap flex-md-row justify-content-md-center mb-5">
                        <a href="tel:+2347033474198" className="btn btn-primary mb-3 mb-lg-0 mr-3">
                            <span className="mr-2"><FaPhoneAlt/></span>
                            Talk to an expert?
                        </a>
                        </div>
                        <Link to="/"
                            className="d-flex align-items-center justify-content-center flex-column">
                            <img src={logo} height="25" className="mb-3" alt="Zicstack Logo"/>
                            <span className="text-muted font-small">An initiative for the modern world fast evolving
                                technology</span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        <ProductDetailsHome/>
        <WhyUs/>
        <br />
        <Faq data={faq} title={"Frequently Asked Questions"}/>
        <Blog title="Shared Knowledge Makes The World Go Round"/>
        <PreFooter/>
    </div>
    <Footer/>
    </>
  )
}

export default Home