import { Link } from "react-router-dom";
import { useState } from "react";
import {FaTimes, FaAngleDown} from 'react-icons/fa'


function Header2() {
const [menuShow, setMenuShow] = useState('');
const [expandMenuClass1, setExpandMenuClass1] = useState('');
const [expandMenuClass2, setExpandMenuClass2] = useState('');
const [expandMenuClass3, setExpandMenuClass3] = useState('');

const handleMenu = () => {
    menuShow === '' && setMenuShow('show')
}
const closeMenu = () => {
    menuShow === 'show' && setMenuShow('')
}

const toggleSubMenu1 = (e) => {
    expandMenuClass1 === "" ? setExpandMenuClass1('show') : setExpandMenuClass1('');
}
const toggleSubMenu2 = (e) => {
    expandMenuClass2 === "" ? setExpandMenuClass2('show') : setExpandMenuClass2('');
}
const toggleSubMenu3 = (e) => {
    expandMenuClass3 === "" ? setExpandMenuClass3('show') : setExpandMenuClass3('');
}


  return (
        <header className="header-global">
        <nav id="navbar-main" aria-label="Primary navigation"
            className="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light navbar-transparent navbar-theme-primary">
            <div className="container position-relative">
                <Link className="navbar-brand shadow-soft py-2 px-3 rounded border border-light mr-lg-4" to="./index.html">
                    <img className="navbar-brand-dark" src="./assets/img/brand/dark.svg" alt="Logo light"/>
                    <img className="navbar-brand-light" src="./assets/img/brand/dark.svg" alt="Logo dark"/>
                </Link>
                <div className={`navbar-collapse collapse ${menuShow}`} id="navbar_global">
                    <div className="navbar-collapse-header">
                        <div className="row">
                            <div className="col-6 collapse-brand">
                                <Link to="./index.html"
                                    className="navbar-brand shadow-soft py-2 px-3 rounded border border-light">
                                    <img src="./assets/img/brand/dark.svg" alt="Themesberg logo"/>
                                </Link>
                            </div>
                            <div className="col-6 collapse-close">
                                <Link to="#navbar_global" data-toggle="collapse"
                                    data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false"
                                    title="close" aria-label="Toggle navigation" onClick={closeMenu}><FaTimes/></Link>
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                        <li className={`nav-item dropdown ${expandMenuClass1}`}>
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <span className="nav-link-inner-text">Pages</span>
                                <span className="nav-link-arrow ml-2" onClick={toggleSubMenu1}><FaAngleDown/></span>
                            </Link>
                            <ul className={`dropdown-menu ${expandMenuClass1}`}>
                                <li><Link className="dropdown-item" to="./html/pages/about.html">About</Link></li>
                                <li><Link className="dropdown-item" to="./html/pages/pricing.html">Pricing</Link></li>
                                <li><Link className="dropdown-item" to="./html/pages/contact.html">Contact</Link></li>
                                <li><Link className="dropdown-item" to="./html/pages/sign-in.html">Sign in</Link></li>
                                <li><Link className="dropdown-item" to="./html/pages/sign-up.html">Sign up</Link></li>
                            </ul>
                        </li>
                        <li className={`nav-item dropdown ${expandMenuClass2} mega-dropdown`}>
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <span className="nav-link-inner-text">Components</span>
                                <span className="nav-link-arrow ml-2" onClick={toggleSubMenu2}><FaAngleDown/></span>
                            </Link>
                            <div className={`dropdown-menu ${expandMenuClass2}`}>
                                <div className="row">
                                    <div className="col-lg-6 inside-bg d-none d-lg-block">
                                        <div className="justify-content-center bg-primary text-white">
                                            <div className="px-6 pb-5 pt-3">
                                                <img src="./assets/img/megamenu-image.jpg" alt="Pixel Components"/>
                                            </div>
                                            <div className="z-2 pb-4 text-center">
                                                <Link to="./html/components/all.html"
                                                    className="btn btn-primary mb-2 mb-sm-0 mr-3 text-secondary">
                                                    <span className="mr-1"><span className="fas fa-th-large"></span></span>
                                                    All components
                                                </Link>
                                                <Link to="https://themesberg.com/docs/neumorphism-ui/components/alerts"
                                                     className="btn btn-primary mb-2 mb-sm-0">
                                                    <span className="mr-1"><span className="fas fa-book"></span></span>
                                                    Docs v1.0
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col pl-0">
                                        <ul className="list-style-none">
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/accordions.html">Accordions</Link></li>
                                            <li><Link className="dropdown-item" to="./html/components/alerts.html">Alerts</Link>
                                            </li>
                                            <li><Link className="dropdown-item" to="./html/components/badges.html">Badges</Link>
                                            </li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/bootstrap-carousels.html">Bootstrap
                                                    Carousels</Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/breadcrumbs.html">Breadcrumbs</Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/buttons.html">Buttons</Link></li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/plugins/jquery-counters/"
                                                    >Counters <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                            <li><Link className="dropdown-item" to="./html/components/cards.html">Cards</Link>
                                            </li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/components/e-commerce/"
                                                    >E-commerce <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="col pl-0">
                                        <ul className="list-style-none">
                                            <li><Link className="dropdown-item" to="./html/components/forms.html">Forms</Link>
                                            </li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/components/icon-boxes/"
                                                    >Icon Boxes <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                            <li><Link className="dropdown-item" to="./html/components/modals.html">Modals</Link>
                                            </li>
                                            <li><Link className="dropdown-item" to="./html/components/navs.html">Navs</Link>
                                            </li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/plugins/owl-carousel/"
                                                    >Owl Carousels <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/pagination.html">Pagination</Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/popovers.html">Popovers</Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/progress-bars.html">Progress Bars</Link></li>

                                        </ul>
                                    </div>
                                    <div className="col pl-0">
                                        <ul className="list-style-none">
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/components/icon-boxes/#steps"
                                                    >Steps <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                            <li><Link className="dropdown-item" to="./html/components/tables.html">Tables</Link>
                                            </li>
                                            <li><Link className="dropdown-item" to="./html/components/tabs.html">Tabs</Link>
                                            </li>
                                            <li><Link className="dropdown-item" to="./html/components/toasts.html">Toasts</Link>
                                            </li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://themesberg.com/docs/neumorphism-ui/components/timelines/"
                                                    >Timelines <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/tooltips.html">Tooltips</Link></li>
                                            <li><Link className="dropdown-item"
                                                    to="./html/components/typography.html">Typography</Link></li>
                                            <li><Link className="dropdown-item d-flex align-items-center justify-content-between"
                                                    to="https://demo.themesberg.com/neumorphism-ui-pro/html/components/widgets.html"
                                                    >Widgets <span
                                                        className="badge badge-dark ml-3">Pro</span></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className={`nav-item dropdown ${expandMenuClass3}`}>
                            <Link to="#" className="nav-link" data-toggle="dropdown">
                                <span className="nav-link-inner-text">Support</span>
                                <span className="nav-link-arrow ml-2" onClick={toggleSubMenu3}><FaAngleDown/></span>
                            </Link>
                            <div className={`dropdown-menu dropdown-menu-lg ${expandMenuClass3}`}>
                                <div className="col-auto px-0" data-dropdown-content>
                                    <div className="list-group list-group-flush">
                                        <Link to="https://themesberg.com/docs/neumorphism-ui/getting-started/quick-start/"
                                            
                                            className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                                            <span className="icon icon-sm icon-secondary"><span
                                                    className="fas fa-file-alt"></span></span>
                                            <div className="ml-4">
                                                <span className="text-dark d-block">Documentation<span
                                                        className="badge badge-sm badge-secondary ml-2">v1.0</span></span>
                                                <span className="small">Examples and guides</span>
                                            </div>
                                        </Link>
                                        <Link to="https://github.com/themesberg/th-neumorphism-ui-pro/issues"
                                            
                                            className="list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4">
                                            <span className="icon icon-sm icon-secondary"><span
                                                    className="fas fa-microphone-alt"></span></span>
                                            <div className="ml-4">
                                                <span className="text-dark d-block">Support</span>
                                                <span className="small">Looking for answers? Ask us!</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-flex align-items-center">
                    <Link to="https://themesberg.com/product/ui-kits/neumorphism-ui-pro" 
                        className="btn btn-primary text-secondary mr-3"><i className="far fa-paper-plane mr-2"></i> Upgrade to
                        PRO</Link>
                    <Link to="https://themesberg.com/docs/neumorphism-ui/getting-started/quick-start/" 
                        className="btn btn-primary d-none d-md-inline-block"><i className="fas fa-book"></i> Docs v1.0
                        </Link>
                    <button className="navbar-toggler ml-2" type="button" data-toggle="collapse"
                        data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="true"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" onClick={handleMenu}></span>
                    </button>
                </div>
            </div>
        </nav>
    </header>
  )
}

export default Header2;