// import ZicCRM from '../assets/img/products/16.svg';
import ZicSMS from '../assets/img/products/17.svg';
import ZicINTRANET from '../assets/img/products/18.svg';
import ZicECOMMERCE from '../assets/img/products/ziccommerce.svg';
// import ZicHRMS from '../assets/img/products/zicHrms.svg';
import ZicAffiliates from '../assets/img/products/ZicAffiliates.svg';
import ZicRealty from '../assets/img/products/ZicREALTY.svg';
import ZicBlog from '../assets/img/products/zicblogs.svg';

const products = [
    {
        imgSrc: ZicAffiliates,
        name: "ZicAFFILIATES",
        imgAlt: "ZicAffiliates Logo",
        link: "/zic-affiliates",
        version: "Latest release",
    },
    {
        imgSrc: ZicRealty,
        name: "ZicREALTY",
        imgAlt: "ZicRealty Logo",
        link: "/zic-realty",
        version: "v1.0",
    },
    {
        imgSrc: ZicINTRANET,
        name: "ZicINTRANET",
        imgAlt: "ZicIntranet Logo",
        link: "/zic-intranet",
        version: "Popular - v1.0",
    },
    {
        imgSrc: ZicECOMMERCE,
        name: "ZicCOMMERCE",
        imgAlt: "ZicCommerce Logo",
        link: "/zic-commerce",
        version: "v7.0",
    },
    {
        imgSrc: ZicBlog,
        name: "ZicBlog",
        imgAlt: "ZicBlog Logo",
        link: "/zic-blog",
        version: "v1.0",
    },
    // {
    //     imgSrc: ZicSMS,
    //     name: "ZicSMS",
    //     imgAlt: "ZicSMS Logo",
    //     link: "/zic-sms",
    //     version: "v7.0",
    // },
    // {
    //     imgSrc: ZicCRM,
    //     name: "ZicCRM",
    //     imgAlt: "ZicCRM Logo",
    //     link: "",
    //     version: "",
    // },
    // {
    //     imgSrc: ZicHRMS,
    //     name: "ZicHRMS",
    //     imgAlt: "ZicHrms Logo",
    //     link: "",
    //     version: "",
    // },
]

export default products;