import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicAffiliates from '../assets/img/products/ZicAffiliates.svg';
import { Link } from 'react-router-dom';
// import intranet from '../assets/img/products/ZicAffiliates.svg';
import affiliate from '../assets/img/illustrations/affiliates.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function Zicintranet() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header mb-n10' style={{zIndex: 10}}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicAffiliates}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<div className='lead mb-5'>
								One of the top leading internet revenue generators is affiliate
								marketing. ZicAffiliates creates an avenue for businesses to pay
								one or more affiliates for each paying client delivered by the
								affiliate's own marketing efforts.
							</div>
							<div className='col text-center'>
							<div className='mt-n5 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										What you get is a SOLUTION to <b>10X</b> your sales
									</div>
								</span>
							</div>
						</div>
							<img
								src={affiliate}
								className='img-fluid'
								alt='ZicCRM Illustration'
							/>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									It is cos -effective, time preservative and scalable. The
									sector includes major players. The customer, the affiliate,
									and the merchant. Our Job is to create a multi-dimensional
									profit relationship between all 3
								</div>
								<hr />
								<div className='mb-0'>
									ZicAffiliates allows you to save time on growing the actual
									paying clients and gives you the chance to concentrate on
									other pressing matters that grow your business.
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4' style={{fontSize: "small"}}>
									Every business's primary goal is to generate enormous profits,
									and in order to do that, it requires customers, and that us
									the speciality of ZicAffiliates.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										The Specialized ZicAffiliate's features:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className='features'>
									<table className='table table-bordered alternating-rows'>
										<thead>
											<tr>
												<th>Key Feature</th>
												<th>Details</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>Precise affiliate tracking</td>
												<td>ZicAffiliates reliably monitors affiliate referrals, even in environments with aggressive caching.</td>
											</tr>
											<tr>
												<td>Real-time reporting</td>
												<td>Monitor visits, referrals, earnings, and affiliate registrations in real-time without any delay.</td>
											</tr>
											<tr>
												<td>Unlimited affiliates</td>
												<td>Support an unlimited number of affiliates actively promoting your website, products, and services.</td>
											</tr>
											<tr>
												<td>Affiliate Area</td>
												<td>Provide a dashboard for affiliates to track performance, view earnings, retrieve referral URLs, find creatives, and more!</td>
											</tr>
											<tr>
												<td>Manual affiliate approval</td>
												<td>Admins can moderate affiliate registrations, automatically accept them, or manually create accounts.</td>
											</tr>
											<tr>
												<td>Automatic affiliate creation</td>
												<td>Enable automatic creation of affiliate accounts for users registering new accounts on your site.</td>
											</tr>
											<tr>
												<td>Referral link generator</td>
												<td>Affiliates can create their own referral links using the built-in generator in the Affiliate Area.</td>
											</tr>
											<tr>
												<td>Export data to CSV</td>
												<td>Export affiliate and referral data to a CSV file for forecasting, bookkeeping, and accounting purposes.</td>
											</tr>
											<tr>
												<td>Customizable emails</td>
												<td>Customize emails for admin notifications, pending affiliate applications, affiliate application approval and rejection, and new referral notifications.</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicAffliates Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading='Keep in Touch' />
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default Zicintranet;
