import Socials from './Socials';
import stars from "../assets/img/illustrations/stars5.png"

function PreFooter() {
	return (
		<section className='section section-lg bg-soft mt-n9'>
			<div className='container'>
				<div className='row justify-content-center text-center text-dark mb-5'>
					<div className='col-lg-9 col-xl-8'>
						<img src={stars} alt="star illustration" width={300}/>
						<h2 className='h1 font-weight-light mb-3 mt-n3'>
							We are simply <span className='font-weight-bold'>the best</span>,
							at
							<span className='font-weight-bold'> process automation</span>.
						</h2>
						<p className='lead'>We can't wait to work with you!</p>
					</div>
				</div>
				<Socials heading='Connect With Us' />
			</div>
		</section>
	);
}

export default PreFooter;
