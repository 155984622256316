const faq = [
    {
        title: "Should I opt for a Web app or a Mobile App?",
        description: "Both options come with their own set of advantages and disadvantages. Mobile apps tend to be more expensive than web applications, but they offer greater data collection and revenue potential. Consider exploring Progressive Web Apps (PWAs), which serve as a middle ground between mobile and web apps. Keep in mind that your needs may evolve, and we are ready to assist you if you require both a mobile and a web app in the future.",
    },
    {
        title: "How long will it take to develop my app?",
        description: "To provide an accurate timeline, we first need to establish a project scope and budget. After approximately a month of work, we can inform you about the achievable scope within the budget and estimate the timeline. If, within the first two weeks, you are not satisfied with our work, you can cancel the project without any charges. This trial period is a standard practice, and we believe any reputable software company should offer it.",
    },
    {
        title: "What are your charges for a website, Mobile App, or Software?",
        description: "The cost depends on various factors, including the project's scope, duration, and technical complexities. While it may seem vague, it is an honest response. Keep in mind that each software project is unique, especially if it involves a novel concept. Instead of providing an inflated quote, our goal is to deliver the highest quality service within your budget constraints.",
    },
    // Additional FAQs
    {
        title: "Do you offer ongoing support and maintenance?",
        description: "Yes, we provide continuous support and maintenance services to ensure the optimal performance and security of your application or software. Our goal is to assist you even after the initial development phase.",
    },
    {
        title: "Can you upgrade or scale my existing application?",
        description: "Absolutely. We offer services to upgrade and scale existing applications based on your evolving needs. Whether it's adding new features, improving performance, or enhancing scalability, we've got you covered.",
    },
    {
        title: "What technologies do you specialize in?",
        description: "Our expertise extends across a diverse range of technologies, allowing us to tailor solutions to your project's specific requirements. Our team is proficient in the latest frameworks and languages, ensuring adaptability and excellence in execution.",
    },
    {
        title: "How do you ensure the security of the applications you develop?",
        description: "Security is a top priority for us. We follow industry best practices and conduct thorough testing to identify and address potential vulnerabilities. Additionally, we stay informed about the latest security trends to proactively enhance the security of our applications.",
    },
    {
        title: "What is the development process you follow?",
        description: "Our development process is collaborative and transparent. We begin by understanding your requirements, create a detailed project plan, and keep you involved throughout the development lifecycle. Regular updates, testing, and client feedback are integral parts of our process to ensure successful project delivery.",
    },
];

export default faq;
