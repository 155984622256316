import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicIntranet from '../assets/img/products/18.svg';
import community from "../assets/img/illustrations/community2.png"
// import zisSmsFeatures from '../Data/products/zicsms/zicsmsFeatures';
// import Faq from '../components/Faq';
import { Link } from 'react-router-dom';
import intranet from '../assets/img/illustrations/intranet.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function Zicintranet() {
	// useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header mb-n10' style={{zIndex: 10}}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicIntranet}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<div className='lead mb-5'>
								ZicIntranet creates visibility, encourages collaboration, Adequate Information sharing, provides social connecting/bonding oportunities for staffs.
							</div>
							<div className='mt-n5 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										What you get is a SOLUTION to <b>10X</b> your sales
									</div>
								</span>
							</div>
							<img src={community} className='img-fluid' alt='ZicCRM Illustration' />
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							{/* <div className='alert alert-success alert-heading shadow-soft justify-content-center'>
								<span>
									<FaBookReader />
									&nbsp;
								</span>
								<span>About</span>
							</div> */}
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									If employees are provided with a way to communicate and
									connect, productivity in an organisation increases by 20 to
									25%.
								</div>
								<hr />
								<div className='mb-0'>
									This is where ZicIntranet comes in: Our intranet software is
									an essential tool to break down communication barriers,
									improve collaboration and drive productivity. And due to the
									nature of the banking and finance industry, monolithic systems
									must be avoided. The institutions must look forward to letting
									go of the age-old systems and using modern financial
									intranets. It also helps in aligning institutions on a
									technological scale, broadening organizational transparency &
									horizon, and portraying modern consumers of our industry.
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4' style={{fontSize: "small"}}>
									If employees are provided with a way to communicate and
									connect, productivity in an organisation increases by 20 to
									25%.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										The Specialized ZicIntranet's features:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
							<div className='features'>
								<table className='table table-bordered alternating-rows'>
									<thead>
										<tr>
											<th>Key Feature</th>
											<th>Details</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Information Management</td>
											<td>The infrastructure used to collect, manage, preserve, store and deliver information</td>
										</tr>
										<tr>
											<td>Document Management System</td>
											<td>A system or process used to capture, track and store electronic documents such as PDFs, word processing files and digital images of paper-based content.</td>
										</tr>
										<tr>
											<td>Mandatory Read</td>
											<td>Perfect in setting reminders for users and back-end reporting</td>
										</tr>
										<tr>
											<td>Administration</td>
											<td>The management of all the operations on the Intranet</td>
										</tr>
										<tr>
											<td>Employee Directory</td>
											<td>An employee directory is a source that hosts important information for all members of an organisation.</td>
										</tr>
										<tr>
											<td>Interactive Workflow and Forms</td>
											<td>A system that provides insight into the company's expenses, for example, and all transactional activities</td>
										</tr>
										<tr>
											<td>Blogging</td>
											<td>Serves executives or the management team as a means of communicating thoughts and strategies to their staff, they can also be created by individuals with the relevant permission to provide updates on projects and to share important information</td>
										</tr>
										<tr>
											<td>Intelligence Features</td>
											<td>Our System provides in-depth Business intelligence that helps the managerial team make critical business decisions and actions.</td>
										</tr>
										<tr>
											<td>Users Able to Add/Update Content</td>
											<td>Permitted Staffs, from the executives to every other staff member, can upload content to the Intranet.</td>
										</tr>
									</tbody>
								</table>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicIntranet Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading='Keep in Touch' />
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default Zicintranet;
