import prodDeet from '../Data/productDetails';
import { Link } from 'react-router-dom';

function ProductDetailsAbout() {
  return (
    <div className='section section-lg pt-0'>
      <div className='container'>
        <div className='row text-center justify-content-center'>
          <div className='col-md-8 mb-5'>
            <h2 className='h4 font-weight-light'>Products in details</h2>
          </div>
        </div>

        <div className='row'>
          {prodDeet.map((item, index) => {
            return (
              <div className='col-md-6 col-lg-4 mb-4' key={index}>
                <div className='card bg-primary shadow-soft border-light d-flex flex-column h-100'>
                  <div className='card-body d-flex flex-column'>
                    <img
                      src={item.logoSrc}
                      className='img-fluid image-sm'
                      alt={item.logoAlt}
                    />
                    <p className='card-text py-5 mb-0'>{item.details}</p>
                    <Link to={item.link} className='btn btn-primary text-dark btn-icon mt-auto'>
                      <span className='btn-inner-text font-weight-bold text-danger'>
                        {item.CTA}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductDetailsAbout;
