// import ZicCRM from '../assets/img/products/16.svg';
import ZicSMS from '../assets/img/products/17.svg';
import ZicINTRANET from '../assets/img/products/18.svg';
import ZicECOMMERCE from '../assets/img/products/ziccommerce.svg';
// import ZicHRMS from '../assets/img/products/zicHrms.svg';
import ZicAffiliates from '../assets/img/products/ZicAffiliates.svg';
import ZicAffiliatesIllustration from '../assets/img/illustrations/affiliates.png';
import ZicRealty from '../assets/img/products/ZicREALTY.svg';
import ZicRealtyIllustration from '../assets/img/illustrations/affiliates.png';
import ZicBlog from '../assets/img/products/zicblogs.svg';
import ZicBlogIllustration from '../assets/img/illustrations/blog.svg';
import commerce from '../assets/img/products/commerce.gif';
// 

import intranet from '../assets/img/illustrations/intranet.png';
import pupils from '../assets/img/illustrations/pupils.gif';

const prodDeet = [
	{
		logoSrc: ZicAffiliates,
		logoAlt: "ZicAffiliates Logo",
		title: 'Customer Relation Management',
		illustrationSrc: ZicAffiliatesIllustration,
		illustrationAlt: "ZicAffiliates Illustration",
		details:
		  "ZicAffiliates is a leading internet revenue generator through affiliate marketing. It provides businesses with a platform to compensate affiliates for each paying client acquired through their marketing efforts.",
		CTA: 'Find out More',
		link: '/zic-affiliates',
	  },
	  {
		logoSrc: ZicRealty,
		logoAlt: "ZicRealty Logo",
		title: 'Real Estate Management Solution',
		illustrationSrc: ZicRealtyIllustration,
		illustrationAlt: "ZicRealty Illustration",
		details:
		  "ZicRealty is a cutting-edge technology-powered Real Estate Engine. Designed specifically for the real estate industry, it offers easy-to-use tools to manage agency content and listings, providing an optimal experience for clients.",
		CTA: 'Find out More',
		link: '/zic-realty',
	  },
	  {
		logoSrc: ZicBlog,
		logoAlt: "ZicBlog Logo",
		title: 'Blog with Super Powers',
		illustrationSrc: ZicBlogIllustration,
		illustrationAlt: "ZicBlog Illustration",
		details:
		  "ZicBlog is an initiative that goes beyond profit-making, aiming to create awareness and guide businesses in establishing Online Authority. It has evolved from being a product/service into a movement.",
		CTA: 'Find out More',
		link: '/zic-blog',
	  },
	// {
	// 	logoSrc: ZicSMS,
	// 	logoAlt: "ZicCRM Logo",
	// 	title: 'School Management System',
	// 	illustrationSrc: pupils,
	// 	illustrationAlt: "ZicSMS Illustration",
	// 	details:
	// 	  "Our School Management System improves your school's performance with analytics and insights at the touch of a button. Efficiently record, manage, and analyze all school and student information.",
	// 	CTA: 'Find out More',
	// 	link: '/zic-sms',
	//   },
	  {
		logoSrc: ZicINTRANET,
		logoAlt: "ZicCRM Logo",
		title: 'Intranet Management System',
		illustrationSrc: intranet,
		illustrationAlt: "ZicINTRANET Illustration",
		details:
		  'Our Intranet solution is a private network contained within an enterprise that is used to securely share company information and computing resources among employees.',
		CTA: 'Find out More',
		link: '/zic-intranet',
	  },
	  {
		logoSrc: ZicECOMMERCE,
		logoAlt: "ZicCRM Logo",
		title: "Zicstack's e-Commerce Solutions",
		illustrationSrc: commerce,
		illustrationAlt: "ZicECOMMERCE Illustration",
		details:
		  'Our eCommerce Solutions allow you to own an online store on the internet where you can professionally list products and services that can be accessed worldwide. Whether you want to be a large marketplace like Jumia, Konga, Amazon, and Alibaba, or you want to sell your own merchandise, we have tools to ease the process for you.',
		CTA: 'Find out More',
		link: '/zic-commerce',
	  },
	  	// {
	// 	logoSrc: ZicCRM,
    //     logoAlt: "ZicCRM Logo",
	// 	title: 'Customer Relation Management',
	// 	illustrationSrc: crm,
	// 	illustrationAlt: "ZicCRM Illustration",
	// 	details:
	// 		'Our Customer Relation Management system is designed to help your organization offer your customers a unique and seamless experience, as well as build better relationships by providing a complete picture of all customer interactions',
	// 	CTA: 'Find out More',
	// 	link: '/ziccrm',
	// },
	// {
	// 	logoSrc: ZicHRMS,
    //     logoAlt: "ZicCRM Logo",
	// 	title: 'Human Resource Management',
	// 	illustrationSrc: hrms,
	// 	illustrationAlt: "ZicHRMS Illustration",
	// 	details:
	// 		'Throughout the employee lifetime, our human resource management system is utilized to manage human resources and related procedures. With the help of this technology, a business may completely comprehend its employees while continuing to adhere to evolving tax and employment requirements.',
	// 	CTA: 'Find out More',
	// 	link: '/zichrms',
	// },
];

export default prodDeet;
