import { FaCode, FaMobile, FaLaptopCode } from 'react-icons/fa';
import { BsInfoLg } from 'react-icons/bs';

const services = [
  {
    icon: <FaCode />,
    title: 'Software Development',
    description: 'Our clients benefit from the software solutions we create to automate their daily business processes. These applications enhance efficiency, speed, data accuracy, accountability, and data protection. If you\'re looking to achieve these improvements for your business, give us a call.',
    CTA: {
      icon: <BsInfoLg />,
      words: 'Learn More',
    },
  },
  {
    icon: <FaLaptopCode />,
    title: 'Web Development',
    description: 'Explore the multitude of opportunities the internet provides. We offer web services that include building platforms to boost efficiency, visibility, and credibility for our clients. By overcoming physical and redundant barriers, we establish "Online Authority" for our clients.',
    CTA: {
      icon: <BsInfoLg />,
      words: 'Learn More',
    },
  },
  {
    icon: <FaMobile />,
    title: 'Mobile App Development',
    description: 'Looking to provide client-centric services? We focus on creating mobile solutions that ensure your customers are comfortable and enjoy using your services, fostering loyalty and transforming potential clients into buyers. Let us help you establish a strong presence in the hearts of your existing clients.',
    CTA: {
      icon: <BsInfoLg />,
      words: 'Learn More',
    },
  },
];

export default services;
