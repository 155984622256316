import prodDeet from '../Data/productDetails';
import { Link } from 'react-router-dom';

function ProductDetailsHome() {
	return (
		<div className='section bg-primary pt-0 section-lg'>
			<div className='container'>
				<div className='row mt-4'>
					{prodDeet.map((item, index) => {
						return <div className='col-md-4' key={index}>
							{/* <!-- Button Modal --> */}
							<button
								type='button'
								className={`btn btn-block btn-primary mb-4`}
								data-bs-toggle='modal'
								data-target='#modal-default'
							>
								<Link to={item.link}>
									<img
									src={item.logoSrc}	
									className='img-fluid image-sm'
									alt={item.logoAlt}
								/>
								</Link>
							</button>
							{/* <!-- Modal Content --> */}
							<div
								className={`item${index} modal fade show`}
								id='modal-default'
								tabIndex='-1'
								role='dialog'
								aria-labelledby='modal-default'
								aria-hidden='true'
								style={{'display': "none"}}
							>
								<div
									className='modal-dialog modal-dialog-centered'
								>
									<div className='modal-content text-center'>
										<div className='modal-header'>
											<h2
												className='h6 modal-title mb-0'
												id='modal-title-default'
											>
												{item.title}
											</h2>
										</div>
										<div className='modal-body text-center'>
											<img src={item.illustrationSrc} alt={item.illustrationAlt} />

											<p className='mt-4'>{item.details}</p>
										</div>
										<div className='modal-footer'>
											<button
												type='button'
												className='btn btn-sm btn-block btn-primary text-danger'
												data-dismiss='modal'
											>
												{item.CTA}
											</button>
										</div>
									</div>
								</div>
							</div>
							{/* <!-- End of Modal Content --> */}
						</div>;
					})}
				</div>
			</div>
		</div>
	);
}

export default ProductDetailsHome;
