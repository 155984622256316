import img1 from '../../../assets/img/clients/portfolio/tilapia/tilapiaClientImg1.png';
import img2 from '../../../assets/img/clients/portfolio/tilapia/tilapiaClientImg2.png';
import img3 from '../../../assets/img/clients/portfolio/tilapia/tilapiaClientImg3.png';

const tilapia = [
		{
			imgSrc: img1,
			imgAlt: 'Tilapia Screenshot',
		},
		{
			imgSrc: img2,
			imgAlt: 'Tilapia Screenshot',
		},
		{
			imgSrc: img3,
			imgAlt: 'Tilapia Screenshot',
		}
	];

export default tilapia;
