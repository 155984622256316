import {
	FaSmile,
} from 'react-icons/fa';
import { GiWorld } from 'react-icons/gi';
import { IoEyeSharp } from 'react-icons/io5';

const socialProof = [
    {
        icon: <FaSmile/>,
        title: "Team Members",
        counter: 10,
    },
    {
        icon: <IoEyeSharp/>,
        title: "Projects Published",
        counter: 50,
    },
    {
        icon: <GiWorld/>,
        title: "Countries",
        counter: 20,
    },
]

export default socialProof;