const qualities = [
    // New qualities for Zicstack
    {
        quality: "Big Experience",
        details: "Our extensive experience over many years in this field is a strong testament to the trust companies place in us, and in return, we provide unique solutions.",
    },
    {
        quality: "Strong Team",
        details: "Our outstanding team of professionals will assist you in bringing your ideas to life in the best possible way, offering flexible functionality.",
    },
    {
        quality: "Personalized Solutions",
        details: "We take an individualized approach to your project, providing flexible solutions for your tasks to help you achieve your goals on the path to success.",
    },
    {
        quality: "Quality",
        details: "We strive to deliver the highest standard, dedicating our best skills and resources to every project.",
    },
    {
        quality: "Performance",
        details: "Each product, service, and module is crafted for optimal performance.",
    },
    {
        quality: "Data Integrity",
        details: "Our operations generate intelligent business data, and we handle each piece with the utmost care and prudence.",
    },
    {
        quality: "Efficiency",
        details: "Our solutions eliminate redundancy, enhancing productivity and efficiency in the daily processes of businesses.",
    },
    {
        quality: "Support",
        details: "We offer continuous support and maintenance to all our existing clients.",
    },
    {
        quality: "Reliability",
        details: "Our systems are robust, fast, and efficient, designed to be 100% reliable.",
    },
];

export default qualities;
