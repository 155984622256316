import EzekielImage from '../assets/img/team/ceo.PNG';
import DayoImage from '../assets/img/team/akinolaDayo2.jpg';
import EstherImage from '../assets/img/team/apetuEsther.jpg';

const creativesData = [
    {
        imgSrc: EzekielImage,
        imgAlt: "Ezekiel's Photo",
        name: "Chief Technology Officer",
        description: "Ezekiel Apetu is deeply enthusiastic about aiding organizations in embracing the diverse possibilities offered by technological solutions. He leverages substantial resources and harnesses the power of the internet to elevate their operations, ensuring success in the dynamic contemporary business environment."
    },
    {
        imgSrc: EstherImage,
        imgAlt: "Esther Apetu's Photo",
        name: "Customer Success Manager",
        description: "Esther Apetu is a real estate prodigy who utilizes her technological expertise to automate processes, boost sales, eliminate redundancy, and foster genuine growth through continuous improvement. She aspires to bridge the gap between technology and business processes."
    },
    {
        imgSrc: DayoImage,
        imgAlt: "Akinola Adedayo's Photo",
        name: "Head of Business",
        description: "Akinola Adedayo, a fashion creative, embarked on his first project to eliminate data loss and inaccuracy in fashion business processes. By blending his business acumen with technical knowledge, he creates scalable solutions for businesses. Starting as a hobbyist in his profession, he now positively impacts numerous businesses."
    },
];

export default creativesData;
