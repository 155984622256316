import { Link } from 'react-router-dom';

function ProjectCTA() {
	return (
		<Link to='/contact'>
			<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center align-items-center'>
				<div className='lead alert-success alert-heading'>
					Ready to start your project? 
				</div>
				<br />
				<div className='lead alert-success alert-heading'> Contact Us Now!</div>
			</div>
		</Link>
	);
}

export default ProjectCTA;
