import ceo from '../assets/img/team/ceo.PNG';
import blog1 from '../assets/img/blog/cyber-security-protection-firewall-interface-concept-768x430.jpg';
import blog2 from '../assets/img/blog/developing-with-a-security-mindset-by-Ezekiel-Apetu.jpg';
import blog3 from '../assets/img/blog/code-4.png';

const blogs = [
    {
        blogHero: blog1,
        blogHeroAlt: "Cyber Security Bog thumbnail",
        writerImg: ceo,
        writerImgAlt: "Apetu Ezekiel Avatar",
        writerName: "Apetu Ezekiel",
        datePublished: "21 Sep 2023",
        blogTitle: "A Guide To Enterprise Solution Security",
        excerpt: "Businesses lean heavily on technology solutions to streamline their operations, boost productivity, and stay competitive. However, with our growing...",
        CTA: {
            link: "https://apetuzee.com/blog/a-guide-to-enterprise-solution-security-3/",
            words: "Read More"
        },
        show: true
    },
    {
        blogHero: blog2,
        blogHeroAlt: "security mindset blog thumbnail",
        writerImg: ceo,
        writerImgAlt: "Apetu Ezekiel Avatar",
        writerName: "Apetu Ezekiel",
        datePublished: "15 March 2023",
        blogTitle: "Developing Solutions With A Security Mindset",
        excerpt: "In today's interconnected world, where technology is seamlessly integrated into our daily lives, ensuring the security of digital solu...",
        CTA: {
            link: "https://apetuzee.com/blog/developing-solutions-with-a-security-mindset/",
            words: "Read More"
        },
        show: true
    },
    {
        blogHero: blog3,
        blogHeroAlt: "Building user trust graphic designed thumbnail",
        writerImg: ceo,
        writerImgAlt: "Apetu Ezekiel Avatar",
        writerName: "Apetu Ezekiel",
        datePublished: "15 Nov 2023",
        blogTitle: "Building User Trust",
        excerpt: "User trust is a priceless asset for any software product or service. Users want to feel confident that their p...",
        CTA: {
            link: "https://apetuzee.com/blog/building-user-trust/",
            words: "Read More"
        },
        show: true
    },
]

export default blogs;