import Header from '../components/Header';
import Footer from '../components/Footer';
import {
	FaBookReader,
} from 'react-icons/fa';
import clients from '../Data/clients';
import FeatureCard from '../components/FeatureCard';
// import products from '../Data/products';
import SocialProof from '../components/SocialProof';
import Creatives from '../components/Creatives';
import Services from '../components/Services';
import CeoSpeech from '../components/CeoSpeech';
import ProductDetailsAbout from '../components/ProductDetailsAbout';
import { useEffect } from 'react';

function About() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			<div className='section section-header pb-7'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>Zicstack</h1>
							<p className='lead mb-5'>
								We are an IT company that specializes in software development,
								cyber security, web and mobile development, as well as other
								targeted process automation solutions while maximizing the power
								of computer programming's specialized tools.
							</p>
							<div className='btn btn-primary'>
								<span>
									<FaBookReader />
									&nbsp;
								</span>
								Our works
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <FeatureCard data={products} title="Our Products"/> */}
            <ProductDetailsAbout/>
			<Services/>
			<CeoSpeech/>
			<SocialProof/>
			<Creatives />
			<FeatureCard data={clients} title="Our Clients" tagLine="We have been privileged to work with some of the best Brands and Company."/>
			<Footer />
		</>
	);
}

export default About;
