import creatives from '../Data/creatives';
import JoinUs from './JoinUs';

function Creatives() {
    return (
        <section className='section section-lg pt-0'>
            <div className='container'>
                <div className='row justify-content-center align-items-center mb-5 d-flex flex-column'>
                    <h2 className='h1'>Our Creatives</h2>
                    <p className=' mb-2 alert-success'>We are a family bonded by love, purpose, and a vision to impact our immediate world.</p>
                    <p className=' mb-5 text-center alert-success'>Our team is made up of technical creatives who run successful businesses. This uniqueness has helped us understand the true needs of businesses, shaping our solutions to be business-centric.</p>
                </div>
                <div className='row'>
                    {creatives.map((item, index) => {
                        return (
							<div key={index} className='col-12 col-md-6 col-lg-4'>
								{/* <!-- Profile Card --> */}
								<div className='card bg-primary shadow-soft border-light text-center h-100 d-flex flex-column'>
									<div className='profile-image shadow-inset border border-light bg-primary rounded-circle p-3 mx-auto mt-3'>
										<img
											src={item.imgSrc}
											className='card-img-top shadow-soft p-2 border border-light rounded-circle'
											alt={item.imgAlt}
										/>
									</div>
									<div className='card-body'>
										<h3 className='h5 mb-2'>{item.name}</h3>
										<span className='h6 font-weight-normal text-gray mb-3' style={{fontSize: "smaller"}}>
											{item.description}
										</span>
									</div>
								</div>
								{/* <!-- End of Profile Card --> */}
							</div>
                        );
                    })}
                </div>
				<br />
				<br />
				<br />
                <JoinUs />
            </div>
        </section>
    );
}

export default Creatives;
