import Footer from '../components/Footer';
import Header from '../components/Header';
import { FaLocationArrow, FaPhoneAlt, FaMailBulk } from 'react-icons/fa';
import { BsFillPersonFill } from 'react-icons/bs';
import Socials from '../components/Socials';
import contact from '../assets/img/illustrations/contact.svg'
import { useEffect } from 'react';

function Contact() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<div>
			<Header />
			<div>
				{/* <!-- Section --> */}
				<section className='section section-lg'>
					<div className='container'>
						<div className='row align-items-center justify-content-center'>
							<div className='col-md-12 col-lg-12 mb-5'>
								{/* <!-- Contact Card --> */}
								<div className='card bg-primary shadow-soft border-light p-2 p-md-3 p-lg-5'>
									<div className='card-header'>
										<div className='row justify-content-center'>
											<div className='col-12'>
											<div className='shadow-inset p-4 mb-5 rounded'>
												<iframe
													title='Map'
													className='map rounded'
													id='gmap_canvas'
													src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.858166649855!2d3.39023261562018!3d6.5395891247882965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8d117005b527%3A0x6e04866ecfc3df34!2s25%20Jagun%20Molu%20St%2C%20Bariga%20102216%2C%20Lagos!5e0!3m2!1sen!2sng!4v1662562342510!5m2!1sen!2sng'
													width='600'
													height='450'
													style={{ border: 0, filter: 'grayscale(0%)' }}
													allowFullScreen=''
													loading='lazy'
													referrerPolicy='no-referrer-when-downgrade'
												></iframe>
											</div>
											</div>
											<div className='col-12 col-md-8 text-center mb-5'>
												<h1 className='display-2 mb-3'>Get in touch today</h1>
												<div className='col-12 col-md-12 mt-4 mt-md-0'>
													<img src={contact} alt='Contact illustration' />
												</div>
												<p className='lead'>
													Have a new project in mind? Need help with an ongoing
													one? Drop us a line about your project needs, we
													answer same day.
												</p>
											</div>
										</div>
										<div className='row mb-5'>
											<div className='col-md-4 col-lg-4 text-center'>
												{/* <!-- Visit Box --> */}
												<div className='icon-box mb-4'>
													<div className='icon icon-shape shadow-soft border-light rounded-circle mb-4'>
														<span>
															<FaLocationArrow />
														</span>
													</div>
													<h2 className='h5 icon-box-title'>Location</h2>
													<span>
														Yaba
														<br />
														Lagos
													</span>
												</div>
												{/* <!-- End of Visit Box --> */}
											</div>
											<div className='col-md-4 col-lg-4 text-center'>
												{/* <!-- Call Box --> */}
												<div className='icon-box mb-4'>
													<div className='icon icon-shape shadow-soft border-light rounded-circle mb-4'>
														<span>
															<FaPhoneAlt />
														</span>
													</div>
													<h2 className='h5 icon-box-title'>Call</h2>
													<span>
														<a href='tel:+2347033474198'>+2347033474198</a>
													</span>
													<div className='text-small text-gray'>
														Mon - Fri, 8am - 4pm
													</div>
												</div>
												{/* <!-- End of Call Box --> */}
											</div>
											<div className='col-md-4 col-lg-4 text-center'>
												{/* <!-- Email Box --> */}
												<div className='icon-box mb-4'>
													<div className='icon icon-shape shadow-soft border-light rounded-circle mb-4'>
														<span>
															<FaMailBulk />
														</span>
													</div>
													<h2 className='h5 icon-box-title'>Email</h2>
													<a href='mailto:info@zicstack.com'>
														info@zicstack.com
													</a>
													<br />
													<a href='mailto:support@zicstack.com'>
														support@zicstack.com
													</a>
												</div>
												{/* <!-- End of Email Box --> */}
											</div>
										</div>
									</div>
									<form className='col-12 col-md-8 mx-auto'>
										{/* <!-- Form --> */}
										<div className='form-group'>
											<label htmlFor='nameInputIcon2'>Your Name</label>
											<div className='input-group mb-4'>
												<div className='input-group-prepend'>
													<span className='input-group-text'>
														<span>
															<BsFillPersonFill />
														</span>
													</span>
												</div>
												<input
													className='form-control'
													id='nameInputIcon2'
													placeholder='e.g. Muhmeen Peters'
													type='text'
													aria-label='contact name input'
												/>
											</div>
										</div>
										{/* <!-- Form --> */}
										<div className='form-group'>
											<label htmlFor='emailInputIcon2'>Your Email</label>
											<div className='input-group mb-4'>
												<div className='input-group-prepend'>
													<span className='input-group-text'>
														<span>
															<FaMailBulk />
														</span>
													</span>
												</div>
												<input
													className='form-control'
													id='emailInputIcon2'
													placeholder='example@company.com'
													type='email'
													aria-label='contact email input'
												/>
											</div>
										</div>
										{/* <!-- Form --> */}
										<div className='form-group'>
											<label htmlFor='exampleFormControlTextarea2'>
												Your Email
											</label>
											<textarea
												className='form-control'
												placeholder='Enter your message...'
												id='exampleFormControlTextarea2'
												rows='4'
											></textarea>
										</div>
										{/* <!-- End of Form --> */}
									</form>
									<div className='card-footer px-0 mx-auto'>
										<button type='submit' className='btn btn-primary'>
											Send message
										</button>
									</div>
								</div>
								{/* <!-- End of Contact Card --> */}
							</div>
						</div>
						<Socials heading='Connect With Us' />
					</div>
				</section>
				{/* <!-- End of section --> */}
			</div>
			<Footer />
		</div>
	);
}

export default Contact;
