import { Link } from 'react-router-dom';
// import { GiPreviousButton, GiNextButton } from 'react-icons/gi';
import { FaPhoneAlt } from 'react-icons/fa';

function Carousels({ data, title }) {
	return (
		<div className='mt-5 d-flex flex-wrap'>
			{title === 'true' && (
				<div className='row'>
					<div className='col text-center'>
						<Link
							to='tel:+2347033474198'
							className='btn btn-primary mb-3 mb-lg-0 mr-3'
						>
							<span className='mr-2'>
								<FaPhoneAlt />
							</span>
							Call us
						</Link>
					</div>
				</div>
			)}
			{data.map((item, index) => {
				return (
					<div key={index} className='row justify-content-center mt-5'>
						<div className='col-md-8'>
							<div
								id='Carousel2'
								className='carousel slide shadow-soft border border-light p-4 rounded'
								data-ride='carousel'
							>
								<div className='carousel-inner rounded'>
									<div className={`carousel-item active`}>
										<img
											className='d-block w-100'
											src={item.imgSrc}
											alt={item.imgAlt}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default Carousels;
