import logo from '../assets/img/brand/logo.svg';
import {FaPaperPlane} from 'react-icons/fa';
import {BsFillBookFill} from 'react-icons/bs';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="header-global">
        <nav id="navbar-main" aria-label="Primary navigation"
            className="navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light">
            <div className="container position-relative">
                <Link className="navbar-brand shadow-soft py-2 px-3 rounded border border-light mr-lg-4"
                    to="/" 
                    // style={{"width": "30px"}}
                    >
                    <img className="navbar-brand-dark" src={logo} alt="Logo light" />
                    <img className="navbar-brand-light" src={logo} alt="Logo dark" />
                </Link>
                <div className="navbar-collapse collapse" id="navbar_global">
                    <div className="navbar-collapse-header">
                        <div className="row">
                            <div className="col-6 collapse-brand">
                                <Link to="../../index.html"
                                    className="navbar-brand shadow-soft py-2 px-3 rounded border border-light">
                                    {/* <img src={logo} alt="Zicstack Logo" /> */}
                                </Link>
                            </div>
                            <div className="col-6 collapse-close">
                                <Link to="#navbar_global" className="fas fa-times" data-toggle="collapse"
                                    data-target="#navbar_global" aria-controls="navbar_global" aria-expanded="false"
                                    title="close" aria-label="Toggle navigation"></Link>
                            </div>
                        </div>
                    </div>
                    <ul className="navbar-nav navbar-nav-hover align-items-lg-center">
                        <li><b>e-commerce gods</b></li>
                    </ul>
                </div>
                <div className="d-flex align-items-center">
                    <Link to="/contact" className="btn btn-primary text-secondary mr-3 contactBtn"><i
                            className="mr-2"><FaPaperPlane/></i> Contact</Link>
                    <Link to="/about" className="btn btn-primary d-md-inline-block aboutBtn"><i
                            className="mr-2"><BsFillBookFill/></i> About Us</Link>

                </div>
            </div>
        </nav>
    </header>
  )
}

export default Header