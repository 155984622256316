import axios from "axios";

console.log("I am here");

export const fetchBlogs = async () => {
    console.log("Trying to fetch");
    try {
      const response = await axios.get('https://apetuzee.com/wp-json/wp/v2/posts/80');
      const fetchedBlogs = response.data;
      console.log("fetchedBlogs", fetchedBlogs);
      return fetchBlogs;

      // Set the fetched blogs to the state
    //   setBlogs(fetchedBlogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  }


  // Call the fetchBlogs function
  fetchBlogs();