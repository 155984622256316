import React, { useState, useEffect } from 'react';
import whyUs from '../Data/whyUs';
import logo from '../assets/img/brand/logo.svg';
import whyUsIllus from '../assets/img/illustrations/whyUs.svg';

function WhyUs() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [showAll, setShowAll] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const visibleWhyUs = showAll ? whyUs : whyUs.slice(0, isMobileView ? 3 : 6);

    return (
        <section className='section section-lg bg-soft'>
            <div className='container'>
                <div className='row justify-content-center align-items-center'>
                    <div className='col-lg-6 text-center mb-5 mb-lg-0'>
                        <h1 className='font-weight-light mb-4'>
                            Why <span className='font-weight-bold'>Us?</span>
                        </h1>
                        <p className='lead'>
                            We are dedicated to providing innovative solutions and delivering value across various professional domains.
                            <br />
                            <b>Join Us!</b>
                        </p>
                    </div>
                    {!isMobileView && (
                        <div className='col-lg-6'>
                            <img src={whyUsIllus} alt='Why Us illustration' className='img-fluid' />
                        </div>
                    )}
                </div>
                <div className='row mt-6'>
                    {visibleWhyUs.map((item, index) => (
                        <div className='col-md-4' key={index}>
                            <div className='icon-box text-center mb-5'>
                                <div className='icon icon-shape icon-shape-sm shadow-soft border border-light rounded-circle'>
                                    <img
                                        className='navbar-brand-dark'
                                        style={{ height: '40px' }}
                                        src={logo}
                                        alt='Zicstack logo'
                                    />
                                </div>
                                <h2 className='h5 my-3'>{item.quality}</h2>
                                <p>{item.details}</p>
                            </div>
                        </div>
                    ))}
                </div>
				{!isMobileView && whyUs.length > 6 && (
					<div className='text-center mt-3'  style={{ cursor: 'pointer', textDecoration: "underline"}}>
						<p
							className='cursor-pointer'
							onClick={() => setShowAll(!showAll)}
						>
							{showAll ? 'Show Less' : 'Show More'}
						</p>
					</div>
				)}
							</div>
						</section>
					);
				}

export default WhyUs;
