function WhatWeDid({ data, client }) {
	return (
		<div className='section bg-primary pt-0 section-lg'>
			<div className='container'>
				{/* <!-- Title  --> */}
				<div className='row'>
					<div className='col text-center'>
						<div className='mb-5'>
							<span className='h5 text-center'>What We did for {client}</span>
						</div>
					</div>
				</div>
				{/* <!-- End of Title --> */}
				<div className='row'>
					{data.map((item, index) => {
						return (
							item.whatWeDid && (
								<div key={index} className='col-md-6 col-lg-6'>
									{/* <!-- Tab Nav --> */}
									<div className='nav-wrapper position-relative mb-4'>
										<ul
											className='nav nav-pills flex-column flex-sm-row'
											id='tabs-text'
											role='tablist'
										>
											<li className='nav-item mr-sm-3 mr-md-0'>
												<div
													className={`nav-link mb-sm-3 mb-md-0`}
													id='tabs-text-1-tab'
												>
													{item.whatWeDid && item.whatWeDid}
												</div>
											</li>
										</ul>
									</div>
									{/* <!-- End of Tab Nav --> */}
									{/* <!-- Tab Content --> */}
									<div className='card shadow-inset bg-primary border-light p-4 rounded'>
										<div className='card-body p-0'>
											<div className='tab-content' id='tabcontent1'>
												<div
													className={`tab-pane fade show active`}
													id='tabs-text-1'
												>
													{item.whatWeDidDescr && item.whatWeDidDescr}
												</div>
											</div>
										</div>
									</div>
									{/* <!-- End of Tab Content --> */}
								</div>
							)
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default WhatWeDid;
