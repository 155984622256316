import { useState } from 'react';
import { BsPlus, BsDash } from 'react-icons/bs';

function Faq({ data, title, width }) {
    const [showAll, setShowAll] = useState(false);
    const visibleFaqs = showAll ? data : data.slice(0, 3);

    return (
        <div>
            <div className='section bg-primary pt-0 section-lg'>
                <div className='container'>
                    {/* <!-- Title --> */}
                    <div className='row'>
                        <div className='col text-center'>
                            <div className='mb-5'>
                                <span className='h5 text-center display-4'>{title}</span>
                            </div>
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className={`col-12 col-md-10 col-lg-${!width ? '8' : width}`}>
                            {visibleFaqs.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='accordion shadow-soft rounded'
                                        id='accordionExample1'
                                    >
                                        <div
                                            className='card card-sm card-body bg-primary border-light mb-0'
                                            title='Click to view details'
                                        >
                                            <a
                                                href={`#panel-${index}`}
                                                data-target={`#panel-${index}`}
                                                className='accordion-panel-header'
                                                data-bs-toggle='collapse'
                                                role='button'
                                                aria-expanded='false'
                                                aria-controls='panel-1'
                                            >
                                                <span className='h6 mb-0 font-weight-bold'>
                                                    {item.title}
                                                </span>
                                                <span className='icon'>
                                                    <span>
                                                        {showAll ? <BsDash /> : <BsPlus />}
                                                    </span>
                                                </span>
                                            </a>
                                            <div className='collapse' id={`panel-${index}`}>
                                                <div className='pt-3'>
                                                    <p className='mb-0'>{item.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {data.length > 3 && (
								<div className='text-center mt-3'  style={{ cursor: 'pointer', textDecoration: "underline"}}>
								<p
									className='cursor-pointer'
									onClick={() => setShowAll(!showAll)}
								>
									{showAll ? 'Show Less' : 'Show More'}
								</p>
							</div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
