import { Link } from 'react-router-dom';
import socials from '../Data/socials';

function Socials({heading}) {
	return (
    <>
			<div className='row mt-5 mb-4 justify-content-center'>
				{/* <div class='col-md-4'> */}
					<span className='h5'>{heading}</span>
				{/* </div> */}
			</div>
		<div className='row alert-dark mt-4 justify-content-center'>
			<div className='row'>
				<div className='col'>
					{/* <!-- Nav Wrapper --> */}
					<div className='nav-wrapper position-relative'>
						<ul className='nav nav-pills nav-pill-circle flex-row flex-md-row flex-sm-row'>
							{socials.map((item, index) => {
								return (
									<li key={index} className='nav-item'>
										<Link
											className='nav-link active'
											aria-label='first navigation tab'
											data-toggle='tab'
											to={item.link}
										>
											<span className='nav-link-icon d-block'>
												<span>{item.icon}</span>
											</span>
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
					{/* <!-- End of Nav Wrapper --> */}
				</div>
			</div>
		</div>
    </>
	);
}

export default Socials;
