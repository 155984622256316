import img1 from '../../../assets/img/clients/portfolio/hotbox/hotboxClientImg1.png';

const hotbox = [
	{
		imgSrc: img1,
		imgAlt: 'hotbox Screenshot',
	},
];

export default hotbox;
