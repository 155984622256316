import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicCommerceLogo from '../assets/img/products/ziccommerce.svg';
import { Link } from 'react-router-dom';
// import intranet from '../assets/img/products/ZicAffiliates.svg';
import commerce from '../assets/img/illustrations/zicCommerceIllus.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function ZicCommerce() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header mb-n10' style={{zIndex: 10}}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicCommerceLogo}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<div className='lead mb-5'>
                            ZicCommerce was created from the ground up to allow users to create the best possible online storefronts for conducting business. We prioritize the needs of the user.
                            <hr />
                            Our users get a lightning-fast and smooth online buying experience without having to keep refreshing the website.
							</div>
							<div className='col text-center'>
							<div className='mt-n5 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										What you get is a SOLUTION to <b>10X</b> your Sales
									</div>
								</span>
							</div>
						</div>
							<img
								src={commerce}
								className='img-fluid mt-n5 mb-n5'
								alt='ZicCRM Illustration'
							/>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
                                With ZicCommerce, you can create any type of online store you can think of and begin generating that passive income you have been considering.
								</div>
								<hr />
								<div className='mb-0'>
                                We offer full automation, including finance systems, inventory, shipping and customer service. That means everything is in one place and can easily be managed by one person at their convenience
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4' style={{fontSize: "small"}}>
                                Conveniently mesh cooperative services via magnetic outsourcing. Dynamically grow value with accurate e-commerce vectors.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										Managing Customers is important and ZicCommerce helps in several ways:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
                            <div className='features'>
                                <table className='table table-bordered alternating-rows'>
                                    <thead>
                                        <tr>
                                            <th>Key Feature</th>
                                            <th>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Google Page Speed</td>
                                            <td>Starting in 2021, one of the crucial elements in Google rankings is website performance. With ZicCommerce, you can stop fighting with sluggish websites.</td>
                                        </tr>
                                        <tr>
                                            <td>e-MarketPlace</td>
                                            <td>With ZicCommerce, you can own gigantic online stores like Jumia, Amazon, Konga, Alibaba, Walmart, and more. Create a platform and begin earning as people buy and sell on it.</td>
                                        </tr>
                                        <tr>
                                            <td>Responsive Design</td>
                                            <td>ZicCommerce provides an entirely responsive design that adapts to the platform it is being viewed on. This allows you to continue doing what you do best, like putting products for sale and building a killer shop, while leaving the technical details in the developers' hands.</td>
                                        </tr>
                                        <tr>
                                            <td>Push Notifications</td>
                                            <td>Inspire customers and prospects to take a specific call to action by sending them notifications that are engaging and effective as a marketing strategy. Make them personal, relevant, and, most of all, provide value to your recipients.</td>
                                        </tr>
                                        <tr>
                                            <td>Retina Ready</td>
                                            <td>Display texts and all media with the highest possible pixel density as per users' device.</td>
                                        </tr>
                                        <tr>
                                            <td>SEO Optimization</td>
                                            <td>Search engines like Google and Bing can locate the relevant information they want in order to rank your website pages properly.</td>
                                        </tr>
                                        <tr>
                                            <td>Social Login and Registration</td>
                                            <td>Allow your users to register/log in using their already existing social accounts like Gmail, Facebook, etc.</td>
                                        </tr>
                                        <tr>
                                            <td>Multi-Language Compatibility</td>
                                            <td>Offer your service to other countries in their native language. The world is at your disposal.</td>
                                        </tr>
                                        <tr>
                                            <td>Integrations with Top Platforms</td>
                                            <td>ZicCommerce provides seamless integrations with Google, Facebook, and other top functionality-based companies globally.</td>
                                        </tr>
                                        <tr>
                                            <td>Account Creation and Maintenance</td>
                                            <td>Allow customers to create and use accounts on your platform with an individual unique and specialized dashboard built for their comfort and continuous engagement on your website.</td>
                                        </tr>
                                        <tr>
                                            <td>And Lots More</td>
                                            <td>You can request a live demo with us.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicCommerce Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading='Keep in Touch' />
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default ZicCommerce;
