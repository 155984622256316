import { Link } from "react-router-dom"

function Card() {
  return (
            <section class="min-vh-100 d-flex bg-primary align-items-left">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 col-lg-6 justify-content-center">
                        <div class="card bg-primary shadow-soft border-light p-4">
                            <div class="card-header text-center pb-0">
                                <h2 class="mb-0 h5">Create Account</h2>                               
                            </div>
                            <div class="card-body">
                                                    <div class="col-12 col-md-6 col-lg-4 mb-5 mb-md-0">
                        {/* <!-- Profile Card --> */}
                        <div class="card bg-primary shadow-inset border-light">
                            <div class="card-header p-4">
                                <img src="./assets/img/team/profile-picture-4.jpg" class="card-img-top rounded"
                                    alt="Wood Portrait"/>
                            </div>
                            <div class="card-body pt-2">
                                <h3 class="h5 mb-2">Christopher Wood</h3>
                                <span class="h6 font-weight-normal text-gray mb-4">Co-Founder Themesberg</span>
                                <p class="card-text">Some quick example text to build on the card title and make up the
                                    bulk of the card's content.</p>
                                <ul class="list-unstyled d-flex my-3">
                                    <li>
                                        <a href="#" target="_blank" aria-label="facebook social link"
                                            class="icon icon-xs icon-facebook mr-3">
                                            <span class="fab fa-facebook-f"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank" aria-label="twitter social link"
                                            class="icon icon-xs icon-twitter mr-3">
                                            <span class="fab fa-twitter"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank" aria-label="slack social link"
                                            class="icon icon-xs icon-slack mr-3">
                                            <span class="fab fa-slack-hash"></span>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank" aria-label="dribbble social link"
                                            class="icon icon-xs icon-dribbble mr-3">
                                            <span class="fab fa-dribbble"></span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* <!-- End of Profile Card --> */}
                    </div>
                                <div class="mt-3 mb-4 text-center">
                                    <span class="font-weight-normal">or</span>
                                </div>
                                <div class="btn-wrapper my-4 text-center">
                                    <button class="btn btn-primary btn-icon-only text-facebook mr-2" type="button" aria-label="facebook button" title="facebook button">
                                        <span aria-hidden="true" class="fab fa-facebook-f"></span>
                                    </button>
                                    <button class="btn btn-primary btn-icon-only text-twitter mr-2" type="button" aria-label="twitter button" title="twitter button">
                                        <span aria-hidden="true" class="fab fa-twitter"></span>
                                    </button>
                                    <button class="btn btn-primary btn-icon-only text-facebook" type="button" aria-label="github button" title="github button">
                                        <span aria-hidden="true" class="fab fa-github"></span>
                                    </button>
                                </div>
                                <div class="d-block d-sm-flex justify-content-center align-items-center mt-4">
                                    <span class="font-weight-normal">
                                        Already have an account?
                                        <Link to="#" class="font-weight-bold">Login here</Link>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default Card