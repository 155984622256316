import socialProof from '../Data/socialProof';

function SocialProof() {
	return (
		<section className='section section-lg pt-0'>
			<div className='container'>
				<div className='row'>
					{socialProof.map((item, index) => {
						return (
							<div key={index} className='col-12 col-sm-4 col-lg-4 text-center'>
								<div className='icon-box mb-4'>
									<div className='icon icon-shape shadow-soft border border-light rounded-circle mb-4'>
										<span>{item.icon}</span>
									</div>
									<h3 className='h5'>{item.title}</h3>
									<span className='counter display-3 text-gray d-block'>
										{item.counter}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</section>
	);
}

export default SocialProof;
