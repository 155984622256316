import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Blog from '../../components/Blog';
import Carousels from '../../components/Carousels';
import dscl from '../../Data/clients/portfolio/dscl';
import PreFooter from '../../components/PreFooter';
import ProjectCTA from '../../components/ProjectCTA';
import Faq from '../../components/Faq';
import whatWeDid from '../../Data/clients/whatWeDid';

function Dscl() {
	return (
		<>
			<Header />
			<div>
				<section className='section section bg-soft pb-5 overflow-hidden z-2'>
					<div className='container z-2'>
						<div className='row justify-content-center text-center pt-6'>
							<div className='col-lg-8 col-xl-8'>
								<h1 className='display-2 mb-3'>
									<b>DSCL</b>
								</h1>
								<p className='lead px-md-6 mb-5'>
									Top Gadgets Company/Store in Nigeria
									<br />
									<b> Motto: Enhancing Communications</b>
								</p>
								{/* <Link
									to='/'
									className='d-flex align-items-center justify-content-center flex-column'
								>
									<img
										src={logo}
										height='25'
										className='mb-3'
										alt='Zicstack Logo'
									/>
								</Link> */}
							</div>
						</div>
						<div className='row justify-content-center mt-4'>
							<div className='col-lg-8 text-center'>
								<div className=''>
									<h1 className='font-weight-light align-items-center px-md-6'>
										What was <span className='font-weight-bold display-3'>our client's</span> need?
									</h1>
								</div>
								<p className='lead'>
									~Client's need Description~
								</p>
							</div>
						</div>
					</div>
				</section>
				<Faq data={whatWeDid} title='What we did for DSCL' width={12} />
				<Carousels data={dscl} />
				<ProjectCTA />
				<Blog title="We keep our Clients updated with e-business tips"/>
				<PreFooter />
			</div>
			<Footer />
		</>
	);
}

export default Dscl;
