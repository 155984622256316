import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicBlogLogo from '../assets/img/products/zicblogs.svg';
import { Link } from 'react-router-dom';
// import intranet from '../assets/img/products/ZicAffiliates.svg';
import zicBlogIllus from '../assets/img/illustrations/zicBlogIllus.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function ZicBlog() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header mb-n10' style={{zIndex: 10}}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicBlogLogo}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<div className='lead mb-5'>
							An initiative born from the belief that businesses' daily operations should transcend beyond only making a profit. ZicBlog has developed from being a product/service into a movement to create awareness and bring businesses into the knowledge of creating Online Authority.
							</div>
							<div className='col text-center'>
							<div className='mt-n5 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										Blog with Super powers!!!
									</div>
								</span>
							</div>
						</div>
							<img
								src={zicBlogIllus}
								className='img-fluid mt-n5 mb-n5'
								alt='ZicCRM Illustration'
							/>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
								ZicBlog is a complete Content creation tool that is a great fit for almost every company, looking to dominate the market. ZicBlog was created to give Leading Market Movers an edge over competitors. It is a movement to dominate the online space which is proven by thousands of references to be the new market. With its clean and modern design, ZicBlog can help you look more professional to your customers and help improve business performance at the same time.
								</div>
								<hr />
								<div className='mb-0'>
								ZicBlog allows you to know more about your customers. Its features allow you to build intel on each and every client over time. Intel, which provides a high level of Business Intelligence.
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4' style={{fontSize: "small"}}>
								The insights gathered by our software can be used for making smart business decisions in the future.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										Managing Customers is important and ZicBlog helps in several ways:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
							<div className='features'>
								<table className='table table-bordered alternating-rows'>
									<thead>
										<tr>
											<th>Key Feature</th>
											<th>Details</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>10+ Worldwide Solutions Integrations</td>
											<td>Seamlessly integrate with more than 10 worldwide solutions to enhance the functionality and reach of your platform.</td>
										</tr>
										<tr>
											<td>eCommerce Compatibility</td>
											<td>Enable eCommerce functionality for selling products directly between blog posts, providing a seamless shopping experience for your users.</td>
										</tr>
										<tr>
											<td>Search Engine Optimization (SEO)</td>
											<td>Optimize your platform for search engines to improve visibility and reach a wider audience.</td>
										</tr>
										<tr>
											<td>Anti-Spamming</td>
											<td>Implement measures to prevent and combat spam activities on your platform, ensuring a clean and secure environment.</td>
										</tr>
										<tr>
											<td>Coordinated Google Search Appearances</td>
											<td>Appear in Google searches with coordinated and strategic appearances to attract more traffic to your content.</td>
										</tr>
										<tr>
											<td>Build Communities Conversations</td>
											<td>Foster community engagement by allowing conversations on every blog post, creating a dynamic and interactive platform.</td>
										</tr>
										<tr>
											<td>Supports Interlinking and External Linking</td>
											<td>Facilitate seamless navigation and information sharing by supporting both interlinking within your platform and external linking to external resources.</td>
										</tr>
										<tr>
											<td>Reader-Friendly Article Sharing</td>
											<td>Enable readers to easily share their favorite articles on your platform with friends and family through various sharing options.</td>
										</tr>
										<tr>
											<td>Smart Media Loading/Processing and Storage</td>
											<td>Optimize media handling with intelligent loading, processing, and storage solutions for an efficient and smooth user experience.</td>
										</tr>
										<tr>
											<td>360 Social Media Functionality</td>
											<td>Integrate comprehensive social media functionality to enhance social sharing, engagement, and reach across various platforms.</td>
										</tr>
										<tr>
											<td>Easy-to-Use Powerful Admin Interface</td>
											<td>Empower administrators with an intuitive and powerful interface for easy management and control of the platform.</td>
										</tr>
										<tr>
											<td>Custom Post Formats</td>
											<td>Support a variety of post formats, including Audio, Video, Standard, Gallery, Link, and Quote, to diversify content presentation.</td>
										</tr>
										<tr>
											<td>Google Map Integration</td>
											<td>Integrate Google Maps to provide location-based information and enhance the user experience.</td>
										</tr>
										<tr>
											<td>Create Awareness through Alerts</td>
											<td>Keep existing readers informed and engaged by sending alerts for new posts, encouraging continuous and updated reading habits.</td>
										</tr>
										<tr>
											<td>Request a Live Demo with Us</td>
											<td>Experience the features firsthand by requesting a live demo with our team.</td>
										</tr>
									</tbody>
								</table>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicBlog Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading='Keep in Touch' />
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default ZicBlog;
