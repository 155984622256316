import img1 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg1.png';
import img2 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg2.png';
import img3 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg3.png';
import img4 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg4.png';
import img5 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg5.png';
import img6 from '../../../assets/img/clients/portfolio/dscl/dsclClientImg6.png';

const dscl = [
		{
			imgSrc: img4,
			imgAlt: 'dscl Screenshot',
			whatWeDid: "whatWeDid",
			whatWeDidDescr: "whatWeDidDescr",
		},
		{
			imgSrc: img3,
			imgAlt: 'dscl Screenshot',
						whatWeDid: "whatWeDid",
			whatWeDidDescr: "whatWeDidDescr",
		},
		{
			imgSrc: img5,
			imgAlt: 'dscl Screenshot',
		},
		{
			imgSrc: img1,
			imgAlt: 'dscl Screenshot',
		},
		{
			imgSrc: img2,
			imgAlt: 'dscl Screenshot',
		},
		{
			imgSrc: img6,
			imgAlt: 'dscl Screenshot',
		}
	];

export default dscl;