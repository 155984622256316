import img1 from '../../../assets/img/clients/portfolio/kingskaftan/kingskaftanClientImg1.jpg';
import img2 from '../../../assets/img/clients/portfolio/kingskaftan/kingskaftanClientImg2.jpg';
import img3 from '../../../assets/img/clients/portfolio/kingskaftan/kingskaftanClientImg3.jpg';
import img4 from '../../../assets/img/clients/portfolio/kingskaftan/kingskaftanClientImg4.png';
import img5 from '../../../assets/img/clients/portfolio/kingskaftan/kingskaftanClientImg5.png';

const kingskaftan = [
		{
			imgSrc: img1,
			imgAlt: 'kingskaftan Screenshot',
		},
		{
			imgSrc: img2,
			imgAlt: 'kingskaftan Screenshot',
		},
		{
			imgSrc: img3,
			imgAlt: 'kingskaftan Screenshot',
		},
		{
			imgSrc: img4,
			imgAlt: 'kingskaftan Screenshot',
		},
		{
			imgSrc: img5,
			imgAlt: 'kingskaftan Screenshot',
		}
	];

export default kingskaftan;