import {FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp, FaBlog} from 'react-icons/fa'

const socials = [
{
    icon: <FaInstagram color='#bc2a8d'/>,
    link: "https://www.instagram.com/zicstack_/",
},
{
    icon: <FaLinkedinIn color='#0e76a8'/>,
    link: "https://www.linkedin.com/company/80824240/",
},
{
    icon: <FaTwitter color='#00acee'/>,
    link: "https://twitter.com/Zicstack_",
},
{
    icon: <FaWhatsapp color='green'/>,
    link: "https://wa.me/+2347033474198",
},
// {
//     icon: <FaBlog color='blue'/>,
//     link: "https://zicstack.net/blog",
// },
]

export default socials;