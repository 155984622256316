
import KennyJones from '../assets/img/clients/logo/kennyjones.svg';
import dscl from '../assets/img/clients/logo/dscl.png';
import tilapia from '../assets/img/clients/logo/tilapia.png';
import kingskaftan from '../assets/img/clients/logo/kingskaftan.svg';
import dmwardrobe from '../assets/img/clients/logo/dmwardrobeLogo.png';
import hotbox from '../assets/img/clients/logo/hotboxBlack.png';
import Nimasa from '../assets/img/clients/logo/nimasa.png';
import Nafdac from '../assets/img/clients/logo/nafdac.png';
// import Bou2ful from '../assets/img/clients/logo/bou2ful.svg';
import Amanda from '../assets/img/clients/logo/amandamalls-logo.png';

const clients = [
    {
        imgSrc: Nimasa,
        imgAlt: "Nimasa Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: Nafdac,
        imgAlt: "Nafdac Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: tilapia,
        imgAlt: "Tilapia Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: dscl,
        imgAlt: "DSCL Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: kingskaftan,
        imgAlt: "Kingskaftan Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: dmwardrobe,
        imgAlt: "DmWardrobe Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: KennyJones,
        imgAlt: "Kennyjones Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: hotbox,
        imgAlt: "Hotbox Logo",
        link: "",
        show: true,
    },
    {
        imgSrc: Amanda,
        imgAlt: "Amanda Logo",
        link: "",
        show: true,
    },
]

export default clients;