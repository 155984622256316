const zisSmsFeatures = [
	{
		title: 'CHAT SUPPORT',
		description:
			'Role permission-based chat management between Admin, Teachers, Students and Parents.',
	},
	{
		title: 'LESSON PLANNER',
		description:
			'Easy to manage all the subject lesson plans, create all section lessons at a time, and have no restriction section.',
	},
	{
		title: 'TEACHER',
		description:
			'Can only see their own information, plans, Students, Classes and Sections, and Subjects, and take attendance.',
	},
	{
		title: 'FEES COLLECTION',
		description:
			'Coordinate Discounts, Manage all types of fees, access Payment history and many more',
	},
	{
		title: 'ADMIN MODULE',
		description:
			'Access to manage other accounts for Teachers, Students, Guardians etc.',
	},
	{
		title: 'STUDENT INFO',
		description:
			'Student Admission, Student List, Student Attendance, Promote, Reports, Daily report, history etc.',
	},
	{
		title: 'EXAMINATION',
		description:
			'Exam routine, Date & time Schedule notice. Seat plan Mark sheet & Report etc',
	},
	{
		title: 'ACADEMICS',
		description:
			'Class Routine Subjective assign Teacher assigns feature Subject etc.',
	},
	{
		title: 'And Lots More',
		description:
			'You Can request a live demo with us '
	},
];

export default zisSmsFeatures;
