import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicREALTY from '../assets/img/products/ZicREALTY.svg';
import { Link } from 'react-router-dom';
// import intranet from '../assets/img/products/ZicAffiliates.svg';
import realEstate from '../assets/img/illustrations/zicRealtySample1.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function ZicRealty() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header mb-n10' style={{zIndex: 10}}>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicREALTY}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<div className='lead mb-5'>
							ZicRealty is a technology driven Engine specially designed for the real estate industry. It offers easy-to-use tools that will allow you to manage your agency’s content and listings, while providing the best possible experience for your clients.
							</div>
							<div className='col text-center'>
							<div className='mt-n5 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										What you get is a SOLUTION to <b>10X</b> your Operations
									</div>
								</span>
							</div>
						</div>
							<img
								src={realEstate}
								className='img-fluid'
								alt='ZicCRM Illustration'
							/>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
								Our content management system enables the publishing of Property content, management of Agents, and Agencies, customer profiling, customer-issue complaint automation, customer purchase tracking system, as well as a bevvy of automation systems to enable a seamless business operation with an eye for Process Standardization
								</div>
								<hr />
								<div className='mb-0'>
								ZicRealty gives you the tools to create and engage with your customers. It offers all the features needed to manage your business, so you can match your brand identity like never before.
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4' style={{fontSize: "small"}}>
								You can show specific properties and customize them any way you want to, letting potential buyers know all about the features of each building.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										The Properties management Portal shall provide the following functionalities:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
							<div className='features'>
								<table className='table table-bordered alternating-rows'>
									<thead>
										<tr>
											<th>Key Feature</th>
											<th>Details</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>360 Virtual Tour</td>
											<td>Include interactive virtual tours that showcase as much of your properties as possible.</td>
										</tr>
										<tr>
											<td>Insight Property Data</td>
											<td>Gain in-depth insight into your real estate site’s visits and see how your listings perform.</td>
										</tr>
										<tr>
											<td>Receive Payments</td>
											<td>ZicRealty lets you get paid with ease – no matter where your clients reside.</td>
										</tr>
										<tr>
											<td>Advanced Search Functionality</td>
											<td>Make it easy for users to make searches based on location, price range, property type, and a number of other important parameters.</td>
										</tr>
										<tr>
											<td>Integrations with World Standard Tools and Platforms</td>
											<td>We have clean integration with tools users worldwide are already familiar with to make the platform 5x more comfortable, usable, relatable, convenient, and efficient in meeting your user's needs.</td>
										</tr>
										<tr>
											<td>Social Networking Integration</td>
											<td>The portal shall be integrated with feeds from the portal's social media pages. Members and Guests can share resources to social media pages from the portal and get hits. Management of these channels is solely dependent on e-Marketplace.</td>
										</tr>
										<tr>
											<td>SEO (Search Engine Optimization)</td>
											<td>The portal shall be registered on the Google search engine and indexed on other global search engines rankings.</td>
										</tr>
										<tr>
											<td>And Lots More</td>
											<td>You can request a live demo with us.</td>
										</tr>
									</tbody>
								</table>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicRealty Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading='Keep in Touch' />
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default ZicRealty;
