import Header from '../components/Header';
import Footer from '../components/Footer';
import { FaBookReader } from 'react-icons/fa';
import ZicCRM from '../assets/img/products/16.svg';
// import zisSmsFeatures from '../Data/products/zicsms/zicsmsFeatures';
// import Faq from '../components/Faq';
import { Link } from 'react-router-dom';
import crm from '../assets/img/illustrations/crm.png';
import Socials from '../components/Socials';
import { useEffect } from 'react';

function Ziccrm() {
	useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
	return (
		<>
			<Header />
			{/* <!-- Hero --> */}
			<div className='section section-header pb-7'>
				<div className='container'>
					<div className='row justify-content-center'>
						<div className='col-12 col-lg-8 text-center'>
							<h1 className='display-2 mb-4'>
								<img
									src={ZicCRM}
									className='img-fluid image-sm'
									alt='ZicCRM logo'
								/>
							</h1>
							<img src={crm} className='img-fluid' alt='ZicCRM Illustration' />
							<div className='lead mb-5'>
								A leading CRM built on cutting-edge technology that caters for
								80% of the Operations of Organizations and Businesses.
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-success alert-heading shadow-soft position-relative mt-4'>
										<span>
											<FaBookReader />
											&nbsp;
										</span>
										About
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center'>
						<div className='col-12 col-md-10'>
							{/* <div className='alert alert-success alert-heading shadow-soft justify-content-center'>
								<span>
									<FaBookReader />
									&nbsp;
								</span>
								<span>About</span>
							</div> */}
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									ZicCRM is a complete Customer Relationship Management software
									that is a great fit for almost any company, freelancer or many
									other uses. With its clean and modern design, ZicCRM can help
									you look more professional to your customers and help improve
									business performance at the same time. The CRM allows you to
									know more about your customers. Its features allow you to
									build intel on each and every client over time. Intel, which
									provides a high level of Business Intelligence.
								</div>
								<hr />
								<div className='mb-0'>
									The insights gathered by our software can be used for making
									smart business decisions in the future. Imagine the “BUSINESS
									INTELLIGENCE” in a couple of years running consistently and
									diligently on the system. Managing customers is important and
									ZicCRM helps in several ways:
								</div>

								<div className='alert alert-success alert-heading shadow-soft position-relative mt-4'>
									Imagine the “BUSINESS INTELLIGENCE” that can be generated a
									couple of years into running operations diligently on our
									system.
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										Managing customers is important and ZicCRM helps in several
										ways:
									</div>
								</span>
							</div>
						</div>
					</div>
					{/* <!-- End of Title --> */}
					<div className='row justify-content-center zindex-modal-backdrop'>
						<div className='col-12 col-md-10'>
							<div className='alert shadow-inset' role='alert'>
								<div className=''>
									<ul className='listings-gap'>
										<li>
											Manage and invoice projects with the powerful Project
											Management Feature,
										</li>
										<li>
											Build professional, great-looking estimates and invoices,
										</li>
										<li>Powerful customer support system,</li>
										<li>
											Create good-looking proposals for leads or customers and
											increase sales,
										</li>
										<li>
											Track time spent on tasks and bill your customers. Ability
											to assign multiple staff members on task and track timer
											per assigned staff,
										</li>
										<li>
											Keep track of leads in one place and easily follow their
											progress. Ability to auto import leads from email and web
											to lead forms, add notes, and create proposals. Organize
											your leads in stages and change stages easily with drag
											and drop,
										</li>
										<li>
											Record your company/project expenses, and have the ability
											to bill your customers and auto convert to invoice,
										</li>
										<li>Increase customer retention via built-in Surveys,</li>
										<li>
											Use the Goals Tracking feature to keep sales goals in
											mind,
										</li>
										<li>
											Create announcements for your staff members and customers,
										</li>
										<li>
											Custom fields can store extra information for customers,
											leads and more,
										</li>
										<li>
											Receive payments from Paypal and Stripe in different
											currencies,
										</li>
										<li>
											Style the CRM to your company branding with the powerful
											theme styling feature,
										</li>
										<li>
											Add task followers even if the staff is not a project
											member. The staff member will be able to track the task
											progress without accessing the project
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<section className='section section-lg bg-soft mt-n7'>
				<div className='container'>
					<div className='row'>
						<div className='col text-center'>
							<div className='mt-6 mb-5'>
								<span className='h5 text-center'>
									<div className='btn alert-heading alert-success'>
										<span>
											{/* <FaBookReader /> */}
											&nbsp;
										</span>
										ZicCRM Specifications
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className='row justify-content-center mb-6'>
						<div className='col-md-10 col-xl-9'>
							<div className='position-relative'>
								<div className='rounded shadow-soft border border-light bg-soft p-4 mb-2'>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Supported Platforms: Windows, Mac, Ubuntu
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Device: Desktop, Phone
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Deployment: Web-Based
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Suitable For: Businesses
										</div>
									</div>
									<div className='mb-3'>
										<div className='font-weight-normal'>
											&gt; Language: English
										</div>
									</div>
								</div>
							</div>
							<Link to='/contact'>
								<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
									<span className='lead alert-success alert-heading'>
										Request a Demo Now
									</span>
								</div>
							</Link>
							<Socials heading="Keep in Touch"/>
						</div>
					</div>
				</div>
			</section>
			{/* <CeoSpeech />
			<SocialProof />
			<Creatives />
			<FeatureCard data={clients} title='Our Clients' /> */}
			<Footer />
		</>
	);
}

export default Ziccrm;
