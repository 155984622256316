import img1 from '../../../assets/img/clients/portfolio/kennyjones/kjClientImg1.png';
import img2 from '../../../assets/img/clients/portfolio/kennyjones/kjClientImg2.png';
import img3 from '../../../assets/img/clients/portfolio/kennyjones/kjClientImg3.png';
import img4 from '../../../assets/img/clients/portfolio/kennyjones/kjClientImg4.png';

const kennyjones = [
	{
		imgSrc: img3,
		imgAlt: 'kennyjones Screenshot',
	},
	{
		imgSrc: img4,
		imgAlt: 'kennyjones Screenshot',
	},
	{
		imgSrc: img1,
		imgAlt: 'kennyjones Screenshot',
	},
	{
		imgSrc: img2,
		imgAlt: 'kennyjones Screenshot',
	},
];

export default kennyjones;
