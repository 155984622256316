import team from '../assets/img/team/team2.svg'
import { Link } from 'react-router-dom';

function JoinUs() {
	return (
		<div className='col-12 col-lg-12'>
			<div className='card bg-primary shadow-soft border-light px-4 py-1 mb-6'>
				<div className='card-body text-center text-md-left'>
					<div className='row align-items-center'>
						<div className='col-md-6'>
							<h2 className='mb-3'>Become one of us</h2>
							<p className='mb-4'>
                                We are open to creative minds, skilled and dedicated hands looking to make a difference in the Tech Space.
                                <br />
								Do you want to join our team and work remotely from anywhere
								you'd like? We can’t wait to hear from you!
							</p>
							<Link to='/contact' className='btn btn-primary'>
								<span className='mr-1'>
									<span className='fas fa-file-invoice'></span>
								</span>
								Contact Us
							</Link>
						</div>
						<div className='col-12 col-md-6 mt-4 mt-md-0 text-md-right'>
							<img
								src={team}
								alt='Team illustration'
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default JoinUs;
