import team from '../assets/img/team/teamTrans.svg';
import signature from '../assets/img/signature.svg';

function CeoSpeech() {
	return (
		<section className='section section-lg pt-0'>
		<div className='container'>
			<div className='row align-items-center justify-content-around'>
			<div className='col-md-6 col-xl-6 mb-5'>
				<div className='card bg-primary shadow-soft border-light organic-radius p-3'>
				<img
					className='organic-radius img-fluid'
					src={team}
					alt='Team Icon'
				/>
				</div>
			</div>
			<div className='col-md-6 col-xl-5 text-center text-md-left'>
				<h2 className='h1 mb-4'>Design with us, Develop Anything.</h2>
				<p className='' style={{ fontStyle: 'italic' }}>
				<strong>Zicstack</strong> comprises a proficient and passionate group encompassing designers, developers, project managers, writers, and artists. Each client collaborates seamlessly with our team, collectively navigating challenges and celebrating achievements. Our compact yet dynamic team actively engages in the creative landscape, maintaining a perpetual curiosity about emerging trends, and exuding an enjoyable atmosphere.
				</p>

				<img src={signature} alt='signature' className='mt-n3' width='100' />
				<p>
				<strong style={{ fontSize: 'larger', fontStyle: 'normal', color: '#333' }}>
					Apetu Ezekiel
				</strong>
				</p>
			</div>
			</div>
		</div>
		</section>
	);
}

export default CeoSpeech;
