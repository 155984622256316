const whatWeDid = [
		{
			title: "title1",
			description: "description1",
            id: "1"
		},
		{
			title: "title2",
			description: "description2",
            id: "2"
		},
		{
			title: "title3",
			description: "description3",
            id: "3"
		},
	];

export default whatWeDid;
