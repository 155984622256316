import About from "./pages/About";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Tilapia from "./pages/clients/Tilapia";
import Kingskaftan from "./pages/clients/Kingskaftan";
import Dscl from "./pages/clients/Dscl";
import Kennyjones from "./pages/clients/Kennyjones";
import Hotbox from "./pages/clients/Hotbox";
import Zicsms from "./products/Zicsms";
import Ziccrm from "./products/Ziccrm";
import Zicintranet from "./products/ZicIntranet";
import Zicaffiliates from "./products/ZicAffiliates";
import ZicBlog from "./products/ZicBlog"
import ZicRealty from "./products/ZicRealty"
import ZicCommerce from "./products/ZicCommerce"
import Header2 from "./components/Header2";
import Header3 from "./components/Header3";
import Card from "./pages/team/Card";
import Teams from "./pages/team/Teams";
import WhatWeDid from "./pages/clients/components/WhatWeDid";
import Lost from "./pages/Lost";

function App() {
  return (
    <div>
    <Router>
      <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/about" exact element={<About/>}/>
        <Route path="/contact" exact element={<Contact/>}/>
        <Route path="/zic-sms" exact element={<Zicsms/>}/>
        <Route path="/zic-crm" exact element={<Ziccrm/>}/>
        <Route path="/zic-intranet" exact element={<Zicintranet/>}/>
        <Route path="/zic-affiliates" exact element={<Zicaffiliates/>}/>
        <Route path="/zic-blog" exact element={<ZicBlog/>}/>
        <Route path="/zic-realty" exact element={<ZicRealty/>}/>
        <Route path="/zic-commerce" exact element={<ZicCommerce/>}/>
        <Route path="/tilapia" exact element={<Tilapia/>}/>
        <Route path="/kingskaftan" exact element={<Kingskaftan/>}/>
        <Route path="/dscl" exact element={<Dscl/>}/>
        <Route path="/kennyjones" exact element={<Kennyjones/>}/>
        <Route path="/hotbox" exact element={<Hotbox/>}/>
        <Route path="/header" exact element={<Header2/>}/>
        <Route path="/header3" exact element={<Header3/>}/>
        <Route path="/card" exact element={<Card/>}/>
        <Route path="/teams" exact element={<Teams/>}/>
        <Route path="/what" exact element={<WhatWeDid/>}/>
        <Route path="*" exact element={<Lost/>}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
