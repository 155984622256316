import logo from '../assets/img/brand/logo.svg';
import { FaPaperPlane, FaTimes, FaAngleDown } from 'react-icons/fa';
import { BsFillBookFill } from 'react-icons/bs';
import { HiMenuAlt3 } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import products from '../Data/products';
import blogs from '../Data/blogs';

function Header() {
	const [menuShow, setMenuShow] = useState('');
	const [expandMenuClass1, setExpandMenuClass1] = useState('');
	const [expandMenuClass2, setExpandMenuClass2] = useState('');
	const [expandMenuClass3, setExpandMenuClass3] = useState('');

	const handleMenu = () => {
		menuShow === '' && setMenuShow('show');
	};
	const closeMenu = () => {
		menuShow === 'show' && setMenuShow('');
	};
	const toggleSubMenu1 = (e) => {
		if (expandMenuClass1 === '') {
			setExpandMenuClass1('show');
			setExpandMenuClass2('');
			setExpandMenuClass3('');
		} else setExpandMenuClass1('');
	};
	const toggleSubMenu2 = (e) => {
		if (expandMenuClass2 === '') {
			setExpandMenuClass2('show');
			setExpandMenuClass1('');
			setExpandMenuClass3('');
		} else setExpandMenuClass2('');
	};
	const toggleSubMenu3 = (e) => {
		if (expandMenuClass3 === '') {
			setExpandMenuClass3('show');
			setExpandMenuClass2('');
			setExpandMenuClass1('');
		} else setExpandMenuClass3('');
	};
	return (
		<header className='header-global' style={{zIndex: 999}}>
			<nav
				id='navbar-main'
				aria-label='Primary navigation'
				className='navbar navbar-main navbar-expand-lg navbar-theme-primary headroom navbar-light'
			>
				<div className='container position-relative'>
					<Link
						className='navbar-brand shadow-soft py-2 px-3 rounded border border-light mr-lg-4'
						to='/'
						// style={{"width": "30px"}}
					>
						<img className='navbar-brand-dark' src={logo} alt='Logo light' />
						<img className='navbar-brand-light' src={logo} alt='Logo dark' />
					</Link>
					<div
						className={`navbar-collapse collapse ${menuShow}`}
						id='navbar_global'
					>
						<div className='navbar-collapse-header'>
							<div className='row'>
								<div className='col-6 collapse-brand'>
									<Link
										to='/'
										className='navbar-brand shadow-soft py-2 px-3 rounded border border-light'
									>
										<img src={logo} alt="Zicstack's logo" />
									</Link>
								</div>
								<div className='col-6 collapse-close'>
									<Link
										to='#navbar_global'
										data-toggle='collapse'
										data-target='#navbar_global'
										aria-controls='navbar_global'
										aria-expanded='false'
										title='close'
										aria-label='Toggle navigation'
										onClick={closeMenu}
									>
										<FaTimes />
									</Link>
								</div>
							</div>
						</div>
						<ul className='navbar-nav navbar-nav-hover align-items-lg-center'>
							<li className='nav-item dropdown firstMenu'>
								<Link to='/about' className='nav-link' data-toggle='dropdown'>
									<span className='nav-link-inner-text'>About Us</span>
								</Link>
							</li>
							<li className='nav-item dropdown'>
								<Link to='#' className='nav-link' data-toggle='dropdown'>
									<span className='nav-link-inner-text'>Blogs</span>
									<span
										className='nav-link-arrow ml-2'
										onClick={toggleSubMenu2}
									>
										<FaAngleDown />
									</span>
								</Link>
								<ul className={`dropdown-menu ${expandMenuClass2}`}>
									{blogs.map((item, index) => {
										return (
											item.show && (
												<li key={index} className='pr-4'>
													<a className='dropdown-item' href={item.CTA.link}>
														<img
															className='blogOnMenu mr-4'
															src={item.blogHero}
															alt={item.blogHeroAlt}
														/>

														{item.blogTitle}
														<span className='badge badge-sm badge-secondary ml-2'>
															{item.version && item.version}
														</span>
													</a>
												</li>
											)
										);
									})}
								</ul>
							</li>
							<li className={`nav-item dropdown ${expandMenuClass1}`}>
								<Link to='#' className='nav-link' data-toggle='dropdown'>
									<span className='nav-link-inner-text'>Products</span>
									<span
										className='nav-link-arrow ml-2'
										onClick={toggleSubMenu1}
									>
										<FaAngleDown />
									</span>
								</Link>
								<ul className={`dropdown-menu ${expandMenuClass1}`}>
									{products.map((item, index) => {
										return (
											<li key={index}>
												<Link className='dropdown-item' to={item.link}>
													{item.name}
													<span className='badge badge-sm badge-secondary ml-2'>
														{item.version && item.version}
													</span>
												</Link>
											</li>
										);
									})}
								</ul>
							</li>
							<li className={`nav-item dropdown ${expandMenuClass3}`}>
								<Link to='#' className='nav-link' data-toggle='dropdown'>
									<span className='nav-link-inner-text'>Support</span>
									<span
										className='nav-link-arrow ml-2'
										onClick={toggleSubMenu3}
									>
										<FaAngleDown />
									</span>
								</Link>
								<div
									className={`dropdown-menu dropdown-menu-lg ${expandMenuClass3}`}
								>
									<div className='col-auto px-0' data-dropdown-content>
										<div className='list-group list-group-flush'>
											<Link
												to='mailto:support@zicstack.com'
												className='list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4'
											>
												<span className='icon icon-sm icon-secondary'>
													<span className='fas fa-file-alt'></span>
												</span>
												<div className='ml-4'>
													<span className='text-dark d-block'>
														Customer Care
													</span>
													<span className='small'>
														We are here to bear your burdens
													</span>
												</div>
											</Link>
											<Link
												to='tel:07033474198'
												className='list-group-item list-group-item-action d-flex align-items-center p-0 py-3 px-lg-4'
											>
												<span className='icon icon-sm icon-secondary'>
													<span className='fas fa-microphone-alt'></span>
												</span>
												<div className='ml-4'>
													<span className='text-dark d-block'>
														Talk to an Expert
													</span>
													<span className='small'>
														Looking for answers? Ask us!
													</span>
												</div>
											</Link>
										</div>
									</div>
								</div>
							</li>
							{/* <li className='nav-item dropdown'>
								<Link to='/about' className='nav-link' data-toggle='dropdown'>
									<span className='nav-link-inner-text'>Testimonials</span>
								</Link>
							</li> */}
						</ul>
					</div>
					<div className='navbar-collapse collapse' id='navbar_global'>
						<div className='navbar-collapse-header'>
							<div className='row'>
								<div className='col-6 collapse-brand'>
									<Link
										to='/'
										className='navbar-brand shadow-soft py-2 px-3 rounded border border-light'
									>
										{/* <img src={logo} alt="Zicstack Logo" /> */}
									</Link>
								</div>
								<div className='col-6 collapse-close'>
									<Link
										to='#navbar_global'
										className='fas fa-times'
										data-toggle='collapse'
										data-target='#navbar_global'
										aria-controls='navbar_global'
										aria-expanded='false'
										title='close'
										aria-label='Toggle navigation'
									></Link>
								</div>
							</div>
						</div>
						{/* <ul className='navbar-nav navbar-nav-hover align-items-lg-center'>
							<li>
								<b>e-commerce gods</b>
							</li>
						</ul> */}
					</div>
					<ul className='navbar-nav navbar-nav-hover align-items-lg-center'>
						<li className='d-none'>
							<b>e-commerce gods</b>
						</li>
					</ul>
					<div className='d-flex align-items-center'>
						<Link to='/contact' className='btn btn-primary text-secondary mr-3'>
							<i className='mr-2'>
								<FaPaperPlane />
							</i>{' '}
							Contact
						</Link>
						<Link
							to='/about'
							className='btn btn-primary d-none d-md-inline-block'
						>
							<i className='mr-2'>
								<BsFillBookFill />
							</i>{' '}
							About Us
						</Link>
						<button
							className='navbar-toggler ml-2'
							type='button'
							data-toggle='collapse'
							data-target='#navbar_global'
							aria-controls='navbar_global'
							aria-expanded='true'
							aria-label='Toggle navigation'
						>
							<span onClick={handleMenu}>
								<HiMenuAlt3 />
							</span>
						</button>
					</div>
				</div>
			</nav>
		</header>
	);
}

export default Header;
