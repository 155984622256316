import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Blog from '../../components/Blog';
import logo from '../../assets/img/brand/logo.svg';
import Carousels from '../../components/Carousels';
import { Link } from 'react-router-dom';
import Socials from '../../components/Socials';
import kennyjones from '../../Data/clients/portfolio/kennyjones';


function Kennyjones() {
	return (
		<>
			<Header />
			<div>
				<section className='section section bg-soft pb-5 overflow-hidden z-2'>
					<div className='container z-2'>
						<div className='row justify-content-center text-center pt-6'>
							<div className='col-lg-8 col-xl-8'>
								<h1 className='display-2 mb-3'><b>Kenny Jones Designs</b></h1>
								<p className='lead px-md-6 mb-5'>
									Top Gadgets Company/Store in Nigeria
									<br />
									<b> Motto: Enhancing Communications</b>
								</p>
								<Link
									to="/"
									className='d-flex align-items-center justify-content-center flex-column'
								>
									<img
										src={logo}
										height='25'
										className='mb-3'
										alt='Zicstack Logo'
									/>
									<span className='text-muted font-small'>
										An initiative for the modern world fast evovling technology
									</span>
								</Link>
							</div>
						</div>
					</div>
				</section>
				<Carousels data={kennyjones} title='true' />
				<Link to='/contact'>
					<div className='row alert alert-dark shadow-soft position-relative mt-4 justify-content-center'>
						<span className='lead alert-success alert-heading'>
							Want Something Similar? Contact Us Now!
						</span>
					</div>
				</Link>
				<div className='p-6'>{/* spacer */}</div>
				<Blog />
				<section className='section section-lg bg-soft'>
					<div className='container'>
						<div className='row justify-content-center text-center text-dark mb-5'>
							<div className='col-lg-9 col-xl-8'>
								<h2 className='h1 font-weight-light mb-3'>
									We are simply{' '}
									<span className='font-weight-bold'>the best</span>, at
									<span className='font-weight-bold'>process automation</span>.
								</h2>
								<p className='lead'>We can't wait to work with you!</p>
							</div>
						</div>
					</div>
					<Socials heading='Keep in Touch' />
				</section>
			</div>
			<Footer />
		</>
	);
}

export default Kennyjones;
